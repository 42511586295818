import Pagination from "@mui/material/Pagination";
import ShowingCount from "./showingCount";
import { GLOBAL_LIMIT } from "../../../helpers/utils/CommonHelpers";

const CustomPaginate = ({
  data,
  selected,
  pageState,
  setPageState,
  setSelected,
  setCurrentOffset,
  totalRecords,
  limit,
  isDisable,
}: any) => {
  let globalLimit: any = GLOBAL_LIMIT;
  const limitPerPage = parseInt(globalLimit);
  let count: any = Math.ceil(totalRecords / limitPerPage);

  const handleChange = (e: any, p: any) => {
    if (pageState === undefined) {
      setSelected(p);
      setCurrentOffset(p * limitPerPage);
    } else {
      setPageState((preValue: any) => ({
        ...preValue,
        selected: p,
        currentOffset: p * limitPerPage,
      }));
    }
  };

  return (
    <>
      <div className="table-info py-5">
        <div className="row g-0">
          <div className="col">
            <ShowingCount
              data={data}
              selected={selected}
              count={totalRecords}
            />
          </div>
          <div className="col pagination justify-content-md-end">
            <Pagination
              className="test"
              count={count}
              color="primary"
              onChange={handleChange}
              page={selected}
              disabled={isDisable}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomPaginate;
