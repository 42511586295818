import { useState } from "react";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth } from "../core/Auth";
import { postRequest } from "../../../../_mis/helpers/APIHelper";
import { LOGIN_URL } from "./../utils/AuthAPIs";
import { loginSchema } from "./validationSchema/LoginSchema";

const initialValues = {
  email: "",
  password: "",
};

export function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { saveAuth, setCurrentUser } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,

    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const formData = {
        email: values.email,
        password: values.password,
      };

      setLoading(true);
      try {
        const userData = await postRequest(navigate, LOGIN_URL, formData);
        if (userData.status == 200) {
          const { data: auth } = userData.data;
          saveAuth(auth);
          const { data: user } = userData.data;
          setCurrentUser(user);
          window.location.reload();
          navigate("/dashboard");
        } else if (userData.status == 422) {
          setMessage(userData.data.message);
          setSubmitting(false);
          setLoading(false);
        } else if (userData.status == 500) {
          setMessage(userData.data.message);
          setSubmitting(false);
          setLoading(false);
        }
      } catch (error) {
        saveAuth(undefined);
        setStatus("The login detail is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-start mb-6">
        <h1 className="text-dark font-17 font-bold">Login to Moweb MIS</h1>
      </div>
      {/* begin::Form group */}
      <div className="fv-row mb-6">
        <label className="form-label text-dark">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container text-danger">
            <span role="alert ">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className="fv-row mb-6">
        <div className="d-flex flex-stack mb-2 justify-content-between">
          {/* begin::Label */}
          <label className="form-label text-dark mb-0">Password</label>
          {/* end::Label */}
          {/* begin::Link */}
          <Link
            to="/auth/forgot-password"
            className="link-primary fw-bolder"
            style={{ marginLeft: "17px" }}
          >
            Forgot Password?
          </Link>
          {/* end::Link */}
        </div>

        <input
          type="password"
          placeholder="Password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container text-danger">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary w-100"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Login</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <br />
        <div className="mt-3">
          <span className="text-danger fw-bold">{message}</span>
        </div>
      </div>
      {/* end::Action */}
    </form>
  );
}
