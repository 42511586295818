import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { manageProfile } from "../reducer/userProfile/reducer";
import { manageDeleteModal } from "../reducer/deleteModal/reducer";

const rootReducers = combineReducers({
  userProfile: manageProfile,
  modal: manageDeleteModal,
});

export const Store = createStore(rootReducers, applyMiddleware(thunk));
