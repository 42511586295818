import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../../../_mis/helpers";
import {
  SET_PASSWORD_LINK_GENERATOR,
  TEAM_MEMBER_GET,
} from "../../utils/TeamMemberAPIs";
import { getRequest, postRequest } from "../../../../../_mis/helpers/APIHelper";
import { toast } from "react-hot-toast";
import IsLoader from "../../../../../_mis/layout/components/loader/loader";

const TeamMemberNameHeader: React.FC = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const id = localStorage.getItem("team-id");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [validationError, setValidationError] = useState<any>([]);
  const [button, setButton] = useState("Sent Reset Password Link");

  const [teamData, setTeamData] = useState<any>([]);

  const fetchTeamMemberDetails = async () => {
    setLoading(true);

    const url = TEAM_MEMBER_GET + "/" + id;
    const response = await getRequest(navigate, url, true);
    setTeamData(response.data);
    setLoading(false);
  };

  const resHandler = (userData: any) => {
    setLoading(true);
    if (userData.status == 200) {
      setMessage(userData.data.message);
      toast.success(userData.data.message);
      setLoading(false);
    } else if (userData.status == 422) {
      setMessage(userData.data.message);
      toast.error(userData.data.message);
      setLoading(false);
    } else if (userData.status == 500) {
      setMessage(userData.data.message);
      toast.error(userData.data.data);
      setValidationError(userData.data.data);
      setLoading(false);
    }
  };

  const generateLink = async () => {
    setButton("Please Wait");
    setLoading(true);
    let data: any = new FormData();

    data.append("email", teamData.email);

    const url = SET_PASSWORD_LINK_GENERATOR;
    const userData = await postRequest(navigate, url, data);
    setButton("Sent Reset Password Link");
    resHandler(userData);
  };

  useEffect(() => {
    fetchTeamMemberDetails();
  }, []);

  const checkValue = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return "-";
    }
    return value;
  };

  return (
    <>
      {loading ? (
        <IsLoader />
      ) : (
        <>
          <div className="card-body pt-5 pb-0">
            <div className="card mb-4 mb-xl-5 pt-5 ps-5 pe-5">
              <div className="d-flex flex-wrap flex-sm-nowrap">
                <div className="flex-grow-1" style={{ marginTop: "10px" }}>
                  <div className="d-flex flex-wrap flex-sm-nowrap">
                    <div className="me-3 me-md-5 mb-3">
                      <div className="symbol symbol-40px symbol-lg-160px symbol-fixed">
                        {teamData.profile ? (
                          <img src={teamData.profile} alt="MIS" />
                        ) : (
                          <img
                            src={toAbsoluteUrl("/media/avatars/img_user.png")}
                            alt="MIS"
                          />
                        )}
                      </div>
                    </div>

                    <div className="flex-grow-1">
                      <div className="d-flex justify-content-between align-items-start flex-wrap">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center mb-1">
                            <span className="text-dark font-20 fw-bold me-1">
                              {teamData.first_name + " " + teamData.last_name}
                            </span>
                          </div>

                          <div className="d-flex flex-wrap mb-2 pe-2 font-14 text-muted">
                            <span className="d-flex align-items-center me-6 mb-2">
                              <KTSVG
                                path="/media/icons/duotune/general/gen017.svg"
                                className="me-1"
                              />
                              {teamData.department &&
                                teamData.department.department_detail && (
                                  <>
                                    {checkValue(
                                      teamData.department.department_detail.name
                                    )}{" "}
                                    -{" "}
                                    {checkValue(
                                      teamData.roles.user_role_detail.name
                                    )}
                                  </>
                                )}
                            </span>
                            <span className="d-flex align-items-center me-5 mb-2 ">
                              <KTSVG
                                path="/media/icons/duotune/general/gen018.svg"
                                className="me-1"
                              />

                              {teamData.work_location &&
                              teamData.work_location.user_work_location_detail
                                ? teamData.work_location
                                    .user_work_location_detail.name === "Hybrid"
                                  ? "Working From Office / Working From Home"
                                  : teamData.work_location
                                      .user_work_location_detail.name === "Home"
                                  ? "Working From Home"
                                  : teamData.work_location
                                      .user_work_location_detail.name ===
                                    "Office"
                                  ? "Working From Office"
                                  : "-"
                                : ""}
                            </span>
                          </div>
                          <div className="d-flex flex-wrap mb-2 pe-2 font-14 text-muted">
                            <span className="d-flex align-items-center me-6 mb-2">
                              <KTSVG
                                path="/media/icons/duotune/communication/com002.svg"
                                className="me-1"
                              />
                              {checkValue(teamData.email)}
                            </span>
                            <span className="d-flex align-items-center me-6 mb-2">
                              <KTSVG
                                path="/media/icons/duotune/electronics/elc002.svg"
                                className="me-1"
                              />
                              {checkValue(teamData.phone_number)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {teamData.email !== null && (
                    <button
                      disabled={button === "Please Wait" ? true : false}
                      className="btn btn-primary me-2"
                      onClick={generateLink}
                    >
                      {button}
                    </button>
                  )}
                  <a href="#" className="btn btn-secondary">
                    {teamData.status == true ? "Active" : "InActive"}
                  </a>
                </div>
              </div>

              <div className="d-flex overflow-auto h-40px">
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap">
                  <li className="nav-item">
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname ===
                          "/team-members/team-member-name/overview" && "active")
                      }
                      to="/team-members/team-member-name/overview"
                    >
                      Overview
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === "#" && "active")
                      }
                      to="#"
                    >
                      Performance
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === "#" && "active")
                      }
                      to="#"
                    >
                      Documents
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === "#" && "active")
                      }
                      to="#"
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        `nav-link text-active-primary me-0 ` +
                        (location.pathname === "#" && "active")
                      }
                      to="#"
                    >
                      Leaves
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TeamMemberNameHeader;
