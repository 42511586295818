import React, { useState, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { initialListView, KTSVG } from "../../../_mis/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { PageTitle } from "../../../_mis/layout/core";


const LeaveDetails = () => {
    const [value, onChange] = useState(new Date());
    const navigate = useNavigate()
    const intl = useIntl()
    const headerTitle = {
        title: "Leaves",
        subTitle: "Leaves"
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [option, setOption] = useState('1')

    return (
        <>
        <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({ id: 'Leaves' })}</PageTitle>

        <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 pt-md-4 px-5 px-md-7 pb-0'>
                            {/* begin::Title */}
                            <div><h3 className="card-title align-items-start flex-column my-0 mb-2">
                                <span className="card-label fw-bold font-20">Ami Patel - Application for Casual Leaves </span>
                            </h3>
                                <p className="d-flex align-items-center mb-0"><KTSVG path='/media/icons/duotune/general/gen014.svg' className='ic me-2' />20 March 2023</p>
                            </div>
                        </div>
                        <div className='card-body'>
                        <div className='table-responsive'>
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-150px'>Leave Type</th>
                                        <th className='min-w-140px'>Date Range</th>
                                        <th className='min-w-120px'>Duration</th>                                    
                                    </tr>
                                </thead>
                                <tbody>
                                         <tr>
                                        <td className=''>LWP</td>
                                        <td className=''>24/03/2023 - 25/03/2023</td>
                                        <td className=''>2 Full Day</td>
                                        </tr>
                                        <tr>
                                        <td className=''>Paid Leave</td>
                                        <td className=''>16/02/2023 - 16/02/2023</td>
                                        <td className=''>First Half Day</td>
                                        </tr>
                                        </tbody>
                                </table>
                            </div>
                            <div className=''>
                                <p className="font-16 fw-500">
                                Reason
                                </p>
                                <hr></hr>
                                <p>
                                I have to attend my sister's marriage function. So, Kindly Approve my leave.
                                </p>                           
                            </div>
                            </div>
                            <div className="card-footer py-4">
                            <button type="button" className="btn btn-light-danger me-3">Cancel </button>
                            <button type="button" className="btn btn-secondary me-3">Reject </button>
                            <button type="submit" className="btn btn-primary">Approve </button>            
              </div>
                    </div>
                    </div>
                    </div>

          </>

)
}


export default LeaveDetails;