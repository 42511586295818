import React from "react";
import { KTSVG } from "../../../helpers";
import { isEmpty } from "../../../helpers/utils/CommonHelpers";

const CustomSearchBar = ({ search, setSearch }: any) => {
  return (
    <div className="col-12 col-md-auto mt-0">
      <div className="d-flex align-items-center">
        <input
          type="text"
          placeholder="Search"
          className="form-control"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
        />

        <>
          {!isEmpty(search) && (
            <span
              onClick={() => {
                setSearch("");
              }}
              className="btn ms-2 py-0 ps-1 pe-0 text-hover-primary btn-sm"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr011.svg"
                className="ic mr-0"
              />
            </span>
          )}
        </>
      </div>
    </div>
  );
};

export default CustomSearchBar;
