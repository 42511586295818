import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { KTSVG, useDebounce } from "../../../../_mis/helpers";
import { getRequest } from "../../../../_mis/helpers/APIHelper";
import {
  GLOBAL_LIMIT,
  checkEmpty,
  isEmpty,
} from "../../../../_mis/helpers/utils/CommonHelpers";
import FetchingRecords from "../../../../_mis/layout/components/loader/fetchingRecords";
import NoRecords from "../../../../_mis/layout/components/noRecords/noRecords";
import CustomPaginate from "../../../../_mis/layout/components/pagination/customPagination";
import CustomSearchBar from "../../../../_mis/layout/components/searchbar/searchbar";
import {
  handleModal,
  submitModal,
} from "../../../../_mis/redux/action/deleteModal/action";
import { TEAM_MEMBER_DELETE, TEAM_MEMBER_LIST } from "../utils/TeamMemberAPIs";
import ShowTechToolTip from "./showTechToolTip";

const TableTeamMembers = (props: any) => {
  const navigate = useNavigate();
  const [teamMember, setTeamMember] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<number>(1);
  const [currentOffset, setCurrentOffset] = useState<number>(1);
  const [count, setCount] = useState<string>("1");
  const searchDebounce = useDebounce(search, 500);

  const getTeamMembersList = async () => {
    setLoading(true);
    const url = `${TEAM_MEMBER_LIST}?search=${searchDebounce}&page=${currentOffset}&per_page=${GLOBAL_LIMIT}`;

    try {
      const response = await getRequest(navigate, url, true);

      let responseData: any = response.data.data;

      responseData.map((res: any, index: number) => {
        responseData[index].email = checkEmpty(res.email, "-");
      });

      setCount(response.data.meta.total);
      setTeamMember(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const handleClick = (id: any) => {
    const url = TEAM_MEMBER_DELETE + id;
    props.handleModal();
    props.submitModalUpdate({ url: url, response: false });
  };

  useEffect(() => {
    getTeamMembersList();
  }, [currentOffset, searchDebounce]);

  useEffect(() => {
    if (props.modalState) {
      if (props.modalState.response) {
        if (props.modalState.response === true) {
          getTeamMembersList();
        }
      }
    }
  }, [props.modalState]);

  const navigateTo = (id: string) => {
    navigate("/team-members/team-member-name/overview");
    localStorage.setItem("team-id", id);
  };

  return (
    <>
      <div className="card">
        <div className="card-header border-0 py-2 px-7 align-items-center">
          <CustomSearchBar search={search} setSearch={setSearch} />
          <div className="ms-auto">
            <div className="card-toolbar d-flex my-0">
              <button
                className="btn btn-sm btn-primary my-1"
                onClick={() => navigate("add-teammember")}
              >
                Add Team Member
              </button>
            </div>
          </div>
        </div>
        <div className="card-body py-3">
          <div className="table-responsive">
            {loading ? (
              <FetchingRecords />
            ) : teamMember.length === 0 ? (
              <>
                <NoRecords />
              </>
            ) : (
              <table className="table table-hover table-row-gray-100 align-middle gs-0 gy-2">
                <thead>
                  <tr className="text-muted fw-500">
                    <th className="min-w-150px">Name</th>
                    <th className="min-w-140px">Email</th>
                    <th className="min-w-120px">Phone Number</th>
                    <th className="min-w-120px">Team</th>
                    <th className="min-w-120px">Tech Stack</th>
                    <th className="min-w-120px">Library</th>
                    <th className="min-w-80px text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {teamMember.length > 0 &&
                    teamMember.map((item: any) => {
                      let fName = item.first_name;
                      let lName = item.last_name;
                      let concatName = "-";
                      if (!isEmpty(fName) && !isEmpty(lName)) {
                        concatName = fName.charAt(0) + lName.charAt(0);
                      }

                      return (
                        <tr className="cursor-pointer">
                          <td
                            onClick={() => {
                              navigateTo(item.id);
                            }}
                            className=""
                          >
                            <div className="d-flex align-items-center">
                              {item.profile === "" ? (
                                <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">
                                  {concatName}
                                </span>
                              ) : (
                                <img
                                  src={item.profile}
                                  className="rounded-circle pro-icon me-1 d-inline-block text-dark font-10"
                                />
                              )}
                              <div className="media-body">
                                <p className="m-0 xs-font">
                                  {isEmpty(item.first_name)
                                    ? ""
                                    : item.first_name}{" "}
                                  {isEmpty(item.last_name)
                                    ? ""
                                    : item.last_name}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td
                            onClick={() => {
                              navigateTo(item.id);
                            }}
                            className=""
                          >
                            {isEmpty(item.email) ? "" : item.email}
                          </td>
                          <td
                            onClick={() => {
                              navigateTo(item.id);
                            }}
                            className=""
                          >
                            {isEmpty(item.phone_number)
                              ? ""
                              : item.phone_number}
                          </td>
                          <td
                            onClick={() => {
                              navigateTo(item.id);
                            }}
                            className=""
                          >
                            {isEmpty(item.team) ? "" : item.team}
                          </td>
                          <td
                            onClick={() => {
                              navigateTo(item.id);
                            }}
                            className=""
                          >
                            {isEmpty(item.tech_stack_count) ? (
                              "-"
                            ) : (
                              <ShowTechToolTip
                                data={item.userTechStack}
                                count={item.tech_stack_count}
                              />
                            )}
                          </td>
                          <td
                            onClick={() => {
                              navigateTo(item.id);
                            }}
                            className=""
                          >
                            {isEmpty(item.user_libraries_count) ? (
                              "-"
                            ) : (
                              <ShowTechToolTip
                                data={item.userLibraries}
                                count={item.user_libraries_count}
                              />
                            )}
                          </td>
                          <td className="text-end">
                            <span
                              onClick={() => {
                                navigate(
                                  `/team-members/edit-teammember/${item.id}`
                                );
                                localStorage.setItem("team-id", item.id);
                              }}
                              className="btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3"
                            >
                              <KTSVG
                                path="/media/icons/duotune/art/art005.svg"
                                className="ic mr-0"
                              />
                            </span>
                            <span
                              onClick={() => handleClick(item.id)}
                              className="btn py-0 ps-1 pe-0 text-hover-primary btn-sm"
                            >
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr011.svg"
                                className="ic mr-0"
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <CustomPaginate
        data={teamMember}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        isDisable={loading}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    modalState: state.modal.modalState,
    isModal: state.modal.isModal,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleModal: () => {
      dispatch(handleModal(""));
    },
    submitModalUpdate: (value: any) => {
      dispatch(submitModal(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableTeamMembers);
