import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { PageTitle } from "../../../_mis/layout/core";
import TableTeamMembers from "./components/tableTeamMembers";
import CustomSearchBar from "../../../_mis/layout/components/searchbar/searchbar";

const TeamMembers = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const headerTitle = {
    title: "Team",
    subTitle: "Team Members",
  };

  return (
    <>
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({ id: "Team Members" })}
      </PageTitle>
      <TableTeamMembers />
    </>
  );
};

export default TeamMembers;
