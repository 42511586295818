import { MODAL_SHOW_HIDE, MODAL_VALUE } from "../../type/type";

export const handleModal = (value: any) => async (dispatch: any) => {
  dispatch({
    type: MODAL_SHOW_HIDE,
    payload: value,
  });
};

export const submitModal = (value: any) => async (dispatch: any) => {
  dispatch({
    type: MODAL_VALUE,
    payload: value,
  });
};
