import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { postRequest } from "../../../helpers/APIHelper";
import { CHANGE_PASSWORD_URL } from "../../../../app/modules/auth/utils/AuthAPIs";
import { useNavigate } from "react-router";
import clsx from "clsx";
import ErrorHandler from "../errorHandler/errorHandler";
import { toast } from "react-hot-toast";

function ChangePassword(props: any) {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("");
  const [newPasswordValidation, setNewPasswordValidation] = useState("");
  const [cnfPasswordValidation, setCnfPasswordValidation] = useState("");
  const [errorMessage, setErrorMessage] = useState<any>([]);
  const [isLoading, setIsLoading] = useState("Submit");

  const handleClose = () => {
    props.onHide();
    setCnfPassword("");
    setCnfPasswordValidation("");
    setNewPassword("");
    setNewPasswordValidation("");
    setPassword("");
    setPasswordValidation("");
    setIsLoading("Submit");
    setErrorMessage("");
  };

  const onSubmitChangePassword = async () => {
    const notify = {
      success: false,
      failed: false,
      errorField: "",
    };

    if (password.trim() == "") {
      setPasswordValidation("is-invalid");
    } else {
      setPasswordValidation("");
    }
    if (newPassword.trim() == "") {
      setNewPasswordValidation("is-invalid");
    } else {
      setNewPasswordValidation("");
    }
    if (cnfPassword.trim() == "") {
      setCnfPasswordValidation("is-invalid");
    } else {
      setCnfPasswordValidation("");
    }

    if (newPassword !== cnfPassword) {
      setErrorMessage("New Password and Confirm must be same.");
    }

    if (password.trim() !== "") {
      if (newPassword.trim() !== "") {
        if (cnfPassword.trim() !== "") {
          if (newPassword == cnfPassword) {
            let body = {
              current_password: password,
              new_password: newPassword,
              confirm_password: cnfPassword,
            };
            setIsLoading("Please Wait...");
            const response = await postRequest(
              navigate,
              CHANGE_PASSWORD_URL,
              body,
              true
            );
            if (response.status === 200) {
              notify.success = true;
              handleClose();
            }
            if (response.status === 500) {
              notify.failed = true;
              // notify.errorField = response.data.current_password
              notify.errorField = response.data.message;
            }
            if (notify.success) {
              setIsLoading("Submit");
              toast.success("Password Changed Successfully.");
              // setDisable(false)
            }
            if (notify.failed) {
              setIsLoading("Submit");
              // setErrorMessage(response.data.message)
              if (
                response.data.data.confirm_password &&
                !response.data.data.current_password
              ) {
                setErrorMessage(response.data.data.confirm_password[0]);
              }
              if (
                response.data.data.current_password &&
                !response.data.data.confirm_password
              ) {
                setErrorMessage(response.data.data.current_password[0]);
              }
              if (
                response.data.data.current_password &&
                response.data.data.confirm_password
              ) {
                setErrorMessage(
                  "confirm password and new password must match and current password is not match with old password"
                );
              }
            }
          }
        }
      }
    }
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title id="contained-modal-title-vcenter">
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <label className="form-label">Current Password</label>
          <input
            type="password"
            placeholder="Type here..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={clsx("form-control psw-input", passwordValidation)}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">New Password</label>
          <input
            type="password"
            placeholder="Type here..."
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className={clsx("form-control psw-input", newPasswordValidation)}
          />
        </div>
        <div>
          <label className="form-label">Confirm Password</label>
          <input
            type="password"
            placeholder="Type here..."
            value={cnfPassword}
            onChange={(e) => setCnfPassword(e.target.value)}
            className={clsx("form-control psw-input", cnfPasswordValidation)}
          />
        </div>
        <div className="mt-3">
          <ErrorHandler errorMessage={errorMessage} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isLoading === "Submit" ? false : true}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={onSubmitChangePassword}
            disabled={isLoading === "Submit" ? false : true}
          >
            {isLoading}
          </Button>
        </Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangePassword;
