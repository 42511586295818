import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_mis/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_mis/layout/core";
import { MasterInit } from "../_mis/layout/MasterInit";
import { AuthInit } from "./modules/auth";
import { Provider } from "react-redux";
import { Store } from "../_mis/redux/store/store";
import DeleteModal from "../_mis/layout/components/deleteModal/deleteModal";

const App = () => {
  return (
    <Provider store={Store}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <DeleteModal />
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </Provider>
  );
};

export { App };
