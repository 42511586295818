import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { deleteRequest } from "../../../helpers/APIHelper";
import { useNavigate } from "react-router";
import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import {
  handleModal,
  submitModal,
} from "../../../redux/action/deleteModal/action";

const DeleteModal = (props: any) => {
  const [button, setButton] = useState("Yes");

  const navigate = useNavigate();

  const deleteMember = async () => {
    setButton("Please Wait");
    let url = "";
    if (props.modalState.url) {
      url = props.modalState.url;
    }
    const response = await deleteRequest(navigate, url, true);
    if (response.status == 200) {
      props.handleModal();
      toast.success("User Deleted Successfully");
      props.submitModal({ url: "", response: true });
    }
    setButton("Yes");
  };

  const closeModal = () => {
    props.submitModal({ url: "", response: false });
    props.handleModal();
  };

  return (
    <>
      <Modal show={props.isModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeModal}
            disabled={button === "Yes" ? false : true}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={deleteMember}
            disabled={button === "Yes" ? false : true}
          >
            {button}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    modalState: state.modal.modalState,
    isModal: state.modal.isModal,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleModal: () => {
      dispatch(handleModal(""));
    },
    submitModal: (value: any) => {
      dispatch(submitModal(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
