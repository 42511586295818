import React from 'react'

const PageNotFound = () => {
    return (
        <h1 className='text-center'>
            Page Not Found
        </h1>
    )
}

export default PageNotFound