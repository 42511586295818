import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageTitle } from "../../../_mis/layout/core";
import {
  TEAM_LIST,
  ROLE_LIST,
  JOBROLE_LIST,
  WORK_LOCATION,
  EDUCATION,
  STORE,
  TEAM_MEMBER_GET,
  TEAM_MEMBER_UPDATE,
  TECH_STACK,
  LIBRARY,
} from "./../teamMembers/utils/TeamMemberAPIs";
import { getRequest, postRequest } from "../../../_mis/helpers/APIHelper";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useFormik } from "formik";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";
import { teamMemberSchema } from "./components/validationSchema";
import IsLoader from "../../../_mis/layout/components/loader/loader";
import {
  checkEmpty,
  checkImage,
  isEmpty,
} from "../../../_mis/helpers/utils/CommonHelpers";
import { toast } from "react-hot-toast";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import { KTSVG } from "../../../_mis/helpers";
import FetchingRecords from "../../../_mis/layout/components/loader/fetchingRecords";

const optionGender = [
  { value: "male", label: "Male" },
  { value: "fmale", label: "Female" },
];

const initialValues = {
  id: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  joiningDate: moment(),
  dateOfBirth: moment(),
  gender: "",
  companyEmail: "",
  personalEmail: "",
  team: "",
  workLocation: "",
  jobRoleType: "",
  role: "",
  experience: "",
  lastWorkingDay: moment(),
  education: "",
  profilePic: "",
  status: "",
  maritalStatus: "",
  shortDescription: "",
  interests: "",
  techStack: "",
  library: "",
};

const AddTeamMembers = () => {
  const [search, setSearchSelect] = useState("");
  const [timer, setTimer] = useState(0);
  const [teamOffSet, setTeamOffSet] = useState("1");
  const [techStackOffSet, setTechStackOffSet] = useState("1");
  const [libraryOffSet, setLibraryOffSet] = useState("1");
  const [interestOptions, setInterestOptions] = useState([]);
  const [team, setTeam] = useState<any>([]);
  const [roleOffSet, setRoleOffSet] = useState("1");
  const [role, setRole] = useState<any>([]);
  const [jobRoleOffSet, setJobRoleOffSet] = useState("1");
  const [jobRoleType, setJobRole] = useState<any>([]);
  const [workLocationOffSet, setWorkLocationOffSet] = useState("1");
  const [workLocation, setWorkLocation] = useState<any>([]);
  const [educationOffSet, setEducationOffSet] = useState("1");
  const [education, setEducation] = useState<any>([]);
  const [image, setImage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState("Submit");
  const [message, setMessage] = useState("");
  const [validationError, setValidationError] = useState<any>([]);
  const [editValue, setEditValue] = useState<any>(initialValues);
  const [handler, setHandler] = useState<any>("add");
  const [gender, setGender] = useState<any>("");
  const [teamMember, setTeamMember] = useState<any>("");
  const [roleDropDown, setRoleDropDown] = useState<any>("");
  const [jobRoleDropDown, setJobRoleDropDown] = useState<any>("");
  const [workLocationDropDown, setWorkLocationDropDown] = useState<any>("");
  const [highEduction, setHighEduction] = useState<any>("");
  const [techStackValues, setTechStackValues] = useState<any>("");
  const [techStack, setTechStack] = useState<any>("");
  const [libraryData, setLibraryData] = useState<any>([]);
  const [library, setLibrary] = useState<any>([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const headerTitle = {
    title: "Team",
    subTitle: handler === "add" ? "Add Team Member" : "Edit Team Member",
  };

  const {
    setValues,
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: editValue,
    validationSchema: teamMemberSchema,
    onSubmit: async (values: any, action: any) => {
      setSubmit("Please Wait");

      let data: any = new FormData();

      if (handler === "edit") {
        data.append("id", values.id);
      }
      data.append("first_name", values.firstName);
      data.append("last_name", values.lastName);
      data.append("phone_number", values.phoneNumber);
      data.append(
        "work_start_date",
        moment(values.joiningDate).format("YYYY-MM-DD")
      );
      data.append("dob", moment(values.dateOfBirth).format("YYYY-MM-DD"));
      data.append("gender", values.gender);
      values.companyEmail !== "" && data.append("email", values.companyEmail);
      values.personalEmail !== "" &&
        data.append("personal_email", values.personalEmail);
      data.append("department_id", values.team);
      data.append("work_location_id", values.workLocation);
      data.append("job_role_type_id", values.jobRoleType);
      data.append("role_id", values.role);
      data.append("total_experience", values.experience);
      data.append(
        "work_end_date",
        moment(values.lastWorkingDay).format("YYYY-MM-DD")
      );

      data.append("education_id", highEduction.value ? highEduction.value : "");
      data.append("profile", values.profilePic);
      data.append("status", handler === "add" ? true : values.status);
      data.append("marital_status", values.maritalStatus);
      data.append("short_description", values.shortDescription);
      data.append("show_interest", JSON.stringify(interestOptions));

      let array = techStackValues;
      for (let i = 0; i < array.length; i++) {
        data.append("tech_stack_ids[]", array[i]);
      }

      let array2 = library;

      if (array2.length > 0) {
        for (let i = 0; i < array2.length; i++) {
          data.append("user_libraries[]", array2[i].value);
        }
      } else {
        data.append("user_libraries[]", null);
      }

      if (handler !== "edit") {
        const userData = await postRequest(navigate, STORE, data, true);
        resHandler(userData);
      } else {
        const url = TEAM_MEMBER_UPDATE;
        const userData = await postRequest(navigate, url, data, true);
        resHandler(userData);
      }
      setSubmit("Submit");
    },
  });

  useEffect(() => {
    libraryOptions();
  }, []);

  useEffect(() => {
    if (location.pathname !== "/team-members/add-teammember") {
      fetchTeam();
      setHandler("edit");
    }
  }, [location]);

  useEffect(() => {
    setTeamOffSet("1");
    setRoleOffSet("1");
    setJobRoleOffSet("1");
    setWorkLocationOffSet("1");
    setEducationOffSet("1");
    setTechStackOffSet("1");
    setLibraryOffSet("1");
  }, [search]);

  useEffect(() => {
    if (handler === "add") {
      let data: any = { ...initialValues };
      let timeKey = ["joiningDate", "dateOfBirth", "lastWorkingDay"];

      for (const key in initialValues) {
        if (timeKey.includes(key)) {
          data[key] = moment();
        } else {
          data[key] = "";
        }
      }

      setValues(data);
      setEditValue(data);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }
  }, [handler]);

  const resHandler = (userData: any) => {
    if (userData.status == 200) {
      setMessage(userData.data.message);
      let alertMessage = "Team Member Added Successfully.";
      if (handler === "edit") {
        alertMessage = "Team Member Updated Successfully.";
      }
      toast.success(alertMessage);
      navigate("/team-members");
      setLoading(true);
    } else if (userData.status == 422) {
      toast.error(userData.data.message);
      setMessage(userData.data.message);
      setLoading(false);
    } else if (userData.status == 500) {
      toast.error(userData.data.data.email[0]);
      setMessage(userData.data.message);
      setValidationError(userData.data.data);
      setLoading(false);
    }
  };

  const handleUrl = (search: string, page: string) => {
    return "?search=" + search + "&page=" + page + "&per_page=10";
  };

  const fetchTeam = async () => {
    setLoading(true);

    const url = TEAM_MEMBER_GET + "/" + id;
    try {
      const response = await getRequest(navigate, url, true);

      const teamData = response.data;

      const {
        education,
        department,
        work_location,
        job_role,
        roles,
        working_detail,
        tech_stack,
        user_libraries,
      } = response.data;

      setImage(teamData.profile);

      let updateTeamMember = initialValues;

      updateTeamMember.id = teamData?.id;
      updateTeamMember.firstName = teamData?.first_name;
      updateTeamMember.lastName = teamData?.last_name;
      updateTeamMember.phoneNumber = teamData?.phone_number;
      updateTeamMember.companyEmail =
        teamData?.email !== null ? teamData.email : "";
      updateTeamMember.personalEmail =
        teamData?.personal_email !== null ? teamData.personal_email : "";
      updateTeamMember.experience = teamData?.total_experience;
      updateTeamMember.shortDescription =
        teamData?.working_detail?.short_description !== null
          ? teamData?.working_detail?.short_description
          : "";
      updateTeamMember.dateOfBirth = moment(teamData?.dob);
      updateTeamMember.joiningDate = moment(
        teamData?.working_detail?.work_start_date
      );
      // some times, the backend returns null values
      updateTeamMember.lastWorkingDay = moment(
        teamData?.working_detail?.work_end_date
      );
      updateTeamMember.team = teamData?.department?.department_id;
      updateTeamMember.gender = teamData?.gender;
      updateTeamMember.status = teamData?.status;
      updateTeamMember.maritalStatus = teamData?.marital_status;
      updateTeamMember.role = teamData?.roles?.user_role_detail?.id;
      updateTeamMember.jobRoleType =
        teamData?.job_role?.job_role_type_detail?.id;
      updateTeamMember.workLocation =
        teamData?.work_location?.user_work_location_detail?.id;

      setGender(optionGender.find((i) => i.value === teamData.gender));

      setTeamMember({
        value: department?.department_id,
        label: department?.department_detail?.name,
      });
      if (education.user_education_detail !== null) {
        setHighEduction({
          value: education?.user_education_detail?.id,
          label: education?.user_education_detail?.name,
        });
      }
      setWorkLocationDropDown({
        value: work_location?.user_work_location_detail?.id,
        label: work_location?.user_work_location_detail?.name,
      });
      setJobRoleDropDown({
        value: job_role?.job_role_type_detail?.id,
        label: job_role?.job_role_type_detail?.name,
      });
      setRoleDropDown({
        value: roles?.user_role_detail?.id,
        label: roles?.user_role_detail?.name,
      });

      // setInterestsKey
      if (working_detail?.show_interest) {
        let interest = JSON.parse(working_detail?.show_interest);
        setInterestOptions(interest);
      }

      let techStackArray: object[] = [];

      if (tech_stack.length > 0) {
        tech_stack.forEach((item: any) => {
          if (item.user_tech_stack_detail && item.user_tech_stack_detail.id) {
            let tech = item.user_tech_stack_detail;
            techStackArray.push({ value: tech.id, label: tech.name });
          }
        });
      }

      setTechStack(techStackArray);

      if (techStackArray.length > 0) {
        updateTeamMember.techStack = "techStack";
      }

      let libraryArray: object[] = [];

      if (user_libraries.length > 0) {
        user_libraries.forEach((item: any) => {
          if (item.user_library_detail) {
            let library = item.user_library_detail;
            libraryArray.push({ value: library.id, label: library.name });
          }
        });
      }

      setLibrary(libraryArray);

      if (libraryArray.length > 0) {
        updateTeamMember.library = "library";
      }

      setEditValue(updateTeamMember);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeValue = (e: any, name?: string) => {
    const inputName = e.target ? e.target.name : name;
    const inputValue = e.target ? e.target.value : e.value;

    setTimer(0);
    let updateValues = values;
    if (inputName === "gender") {
      updateValues["gender"] = inputValue;
    } else if (inputName === "team") {
      updateValues["team"] = inputValue;
    } else if (inputName === "workLocation") {
      updateValues["workLocation"] = inputValue;
    } else if (inputName === "jobRoleType") {
      updateValues["jobRoleType"] = inputValue;
    } else if (inputName === "role") {
      updateValues["role"] = inputValue;
    } else if (inputName === "education") {
      updateValues["education"] = inputValue;
    } else if (inputName === "maritalStatus") {
      updateValues["maritalStatus"] = inputValue;
    } else if (inputName === "techStack") {
      if (e && e.length > 0) {
        updateValues["techStack"] = "e";
      } else {
        updateValues["techStack"] = "";
      }
    } else if (inputName === "library") {
      if (e && e.length > 0) {
        updateValues["library"] = "e";
      } else {
        updateValues["library"] = "";
      }
    } else if (inputName === "profilePic") {
      updateValues["profilePic"] = e.target.files[0];
      setImage(URL.createObjectURL(e.target.files[0]));
    } else {
      setTimer(1000);
      setSearchSelect(inputValue);
    }
    setValues(updateValues);
  };

  const extendedTeamLoadOptions = async (search?: any) => {
    if (search != "") {
      setSearchSelect(search);
    }

    const url = TEAM_LIST + handleUrl(search, teamOffSet);

    const result = await getRequest(navigate, url, true);
    let options: any = [];
    let hasMore: boolean = false;

    if (result.data.links.next !== null && result.data.data.length > 0) {
      const link = result.data.links.next;
      let splitUrl = link.split("?")[1];
      let newOffset: any = splitUrl.replace("page=", "");
      setTeamOffSet(newOffset);
      hasMore = true;
    }

    if (teamOffSet.length == 0) {
      options.push({
        label: "All Team List",
        value: "",
      });
    }

    if (result.data.data.length > 0) {
      result.data.data.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        });
      });
    }

    if (teamOffSet.length > 0) {
      setTeam(team.concat(options));
    } else {
      setTeam(options);
    }

    return {
      options: options,
      hasMore: hasMore,
    };
  };

  const extendedTechStockLoadOptions = async (search?: any) => {
    if (search != "") {
      setSearchSelect(search);
    }

    const url = TECH_STACK + handleUrl(search, techStackOffSet);

    const result = await getRequest(navigate, url, true);
    let options: any = [];
    let hasMore: boolean = false;

    if (result.data.links.next !== null && result.data.data.length > 0) {
      const link = result.data.links.next;
      let splitUrl = link.split("?")[1];
      let newOffset: any = splitUrl.replace("page=", "");
      setTechStackOffSet(newOffset);
      hasMore = true;
    }

    if (techStackOffSet.length == 0) {
      options.push({
        label: "All Tech Stack List",
        value: "",
      });
    }

    if (result.data.data.length > 0) {
      result.data.data.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        });
      });
    }

    return {
      options: options,
      hasMore: hasMore,
    };
  };

  const libraryOptions = async () => {
    const url = `${LIBRARY}?per_page=100`;

    const result = await getRequest(navigate, url, true);

    if (result && result.data && result.data.data) {
      if (result.data.data.length > 0) {
        let data: any = [];
        result.data.data.forEach((item: any) => {
          data.push({
            label: item.name,
            value: item.id,
          });
        });
        setLibraryData(data);
      }
    }
  };

  const extendedLibraryLoadOptions: any = async (search?: any) => {
    if (search != "") {
      setSearchSelect(search);
    }

    const url = LIBRARY + handleUrl(search, libraryOffSet);

    const result = await getRequest(navigate, url, true);
    let options: any = [];
    let hasMore: boolean = false;

    if (result.data.links.next !== null && result.data.data.length > 0) {
      const link = result.data.links.next;
      let splitUrl = link.split("?")[1];
      let newOffset: any = splitUrl.replace("page=", "");
      setLibraryOffSet(newOffset);
      hasMore = true;
    }

    if (libraryOffSet.length == 0) {
      options.push({
        label: "All Library List",
        value: "",
      });
    }

    if (result.data.data.length > 0) {
      result.data.data.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        });
      });
    }

    return {
      options: options,
      hasMore: hasMore,
    };
  };
  const extendedRoleLoadOptions = async (search?: any) => {
    if (search != "") {
      setSearchSelect(search);
    }

    const url = ROLE_LIST + handleUrl(search, roleOffSet);
    const result = await getRequest(navigate, url, true);
    let options: any = [];
    let hasMore: boolean = false;

    if (result.data.links.next !== null && result.data.data.length > 0) {
      const link = result.data.links.next;
      let splitUrl = link.split("?")[1];
      let newOffset: any = splitUrl.replace("page=", "");
      setRoleOffSet(newOffset);
      hasMore = true;
    }

    if (roleOffSet.length == 0) {
      options.push({
        label: "All Role List",
        value: "",
      });
    }

    if (result.data.data.length > 0) {
      result.data.data.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        });
      });
    }

    if (roleOffSet.length > 0) {
      setRole(role.concat(options));
    } else {
      setRole(options);
    }

    return {
      options: options,
      hasMore: hasMore,
    };
  };

  const extendedJobRoleLoadOptions = async (search?: any) => {
    if (search != "") {
      setSearchSelect(search);
    }

    const url = JOBROLE_LIST + handleUrl(search, jobRoleOffSet);
    const result = await getRequest(navigate, url, true);
    let options: any = [];
    let hasMore: boolean = false;

    if (result.data.links.next !== null && result.data.data.length > 0) {
      const link = result.data.links.next;
      let splitUrl = link.split("?")[1];
      let newOffset: any = splitUrl.replace("page=", "");
      setJobRoleOffSet(newOffset);
      hasMore = true;
    }

    if (jobRoleOffSet.length == 0) {
      options.push({
        label: "All Job Role List",
        value: "",
      });
    }

    if (result.data.data.length > 0) {
      result.data.data.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        });
      });
    }

    if (jobRoleOffSet.length > 0) {
      setJobRole(jobRoleType.concat(options));
    } else {
      setJobRole(options);
    }

    return {
      options: options,
      hasMore: hasMore,
    };
  };

  const extendedWorkLocationLoadOptions = async (search?: any) => {
    if (search != "") {
      setSearchSelect(search);
    }

    const url = WORK_LOCATION + handleUrl(search, workLocationOffSet);
    const result = await getRequest(navigate, url, true);
    let options: any = [];
    let hasMore: boolean = false;

    if (result.data.links.next !== null && result.data.data.length > 0) {
      const link = result.data.links.next;
      let splitUrl = link.split("?")[1];
      let newOffset: any = splitUrl.replace("page=", "");
      setWorkLocationOffSet(newOffset);
      hasMore = true;
    }

    if (workLocationOffSet.length == 0) {
      options.push({
        label: "All Work Location List",
        value: "",
      });
    }

    if (result.data.data.length > 0) {
      result.data.data.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        });
      });
    }

    if (workLocationOffSet.length > 0) {
      setWorkLocation(workLocation.concat(options));
    } else {
      setWorkLocation(options);
    }

    return {
      options: options,
      hasMore: hasMore,
    };
  };

  const extendedEducationLoadOptions = async (search?: any) => {
    if (search != "") {
      setSearchSelect(search);
    }

    const url = EDUCATION + handleUrl(search, educationOffSet);
    const result = await getRequest(navigate, url, true);
    let options: any = [];
    let hasMore: boolean = false;

    if (result.data.links.next !== null && result.data.data.length > 0) {
      const link = result.data.links.next;
      let splitUrl = link.split("?")[1];
      let newOffset: any = splitUrl.replace("page=", "");
      setEducationOffSet(newOffset);
      hasMore = true;
    }

    if (educationOffSet.length == 0) {
      options.push({
        label: "All Education List",
        value: "",
      });
    }

    if (result.data.data.length > 0) {
      result.data.data.map((option: any) => {
        options.push({
          label: option.name,
          value: option.id,
        });
      });
    }

    if (educationOffSet.length > 0) {
      setEducation(education.concat(options));
    } else {
      setEducation(options);
    }

    return {
      options: options,
      hasMore: hasMore,
    };
  };

  useEffect(() => {
    if (techStack !== "") {
      let techId: string[] = [];
      let masterId = techStack;
      masterId.forEach((item: any) => {
        techId.push(item.value);
      });

      setTechStackValues(techId);
    }
  }, [techStack]);

  return (
    <>
      <PageTitle breadcrumbs={[headerTitle]}>
        {intl.formatMessage({ id: "Team Members" })}
      </PageTitle>
      {loading ? (
        <FetchingRecords />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="row mt-3 mt-md-0">
              <div className="col-12 mt-md-0">
                <div className="card card-xl-stretch mb-5">
                  <div className="card-header border-0 py-3 py-md-2 px-5 px-md-7 align-items-center">
                    {/* begin::Title */}
                    <h3 className="card-label fw-bold font-17 mb-0">
                      Personal Details
                    </h3>
                    {/* end::Title */}
                  </div>
                  <div className="card-body pt-2">
                    <div className="row">
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <input
                          type="hidden"
                          name="id"
                          id="id"
                          className="form-control"
                          placeholder="id"
                          value={values.id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className="form-label">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          className="form-control"
                          placeholder="First Name"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.firstName && touched.firstName ? (
                          <p className="form-error text-danger">
                            {errors.firstName}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <label className="form-label">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          className="form-control"
                          placeholder="Last Name"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.lastName && touched.lastName ? (
                          <p className="form-error text-danger">
                            {errors.lastName}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <label className="form-label">Date of Birth</label>
                        <DateRangePicker
                          onCallback={(start: any, end: any, label: any) => {
                            let newDate = start._d;
                            values["dateOfBirth"] =
                              moment(newDate).format("YYYY-MM-DD");
                            //setJoinDate(newDate.toLocaleString());
                          }}
                          initialSettings={{
                            startDate: values.dateOfBirth,
                            endDate: values.dateOfBirth,
                            minYear: "1901",
                            maxYear: moment().format("YYYY"),
                            showDropdowns: true,
                            alwaysShowCalendars: true,
                            cancelClass: "btn-secondary",
                            singleDatePicker: true,
                            maxDate: moment(Date.now()),
                            drops: "auto",
                            locale: {
                              format: "MM/DD/YYYY",
                            },
                          }}
                        >
                          <input
                            type="text"
                            name="dateOfBirth"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // value={values.dateOfBirth}
                            id="dateOfBirth"
                            className="form-control calendar"
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label className="form-label">Highest Education</label>
                        <AsyncPaginate
                          loadOptions={extendedEducationLoadOptions}
                          isSearchable
                          classNamePrefix="react-select"
                          value={highEduction}
                          theme={(theme: any) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#0c78a4",
                            },
                          })}
                          name="education"
                          id="education"
                          onChange={(e: any) => {
                            handleChangeValue(e, "education");
                            setHighEduction(e);
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">
                          Show Your Interests
                        </label>
                        <CreatableSelect
                          isMulti
                          value={interestOptions}
                          onChange={(e: any) => {
                            setInterestOptions(e);
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">
                          Gender <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={optionGender}
                          isSearchable
                          className="react-select-container"
                          classNamePrefix="react-select"
                          theme={(theme: any) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#0c78a4",
                            },
                          })}
                          name="gender"
                          id="gender"
                          value={gender}
                          onChange={(e: any) => {
                            handleChangeValue(e, "gender");
                            setGender(e);
                          }}
                          onBlur={handleBlur}
                        />

                        {errors.gender && touched.gender ? (
                          <p className="form-error text-danger">
                            {errors.gender}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">Marital Status</label>
                        <div className="d-flex align-items-center">
                          <div className="form-check form-check-custom form-check-solid form-check-sm me-4">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="Married"
                              onChange={(e: any) =>
                                handleChangeValue(e, "maritalStatus")
                              }
                              onBlur={handleBlur}
                              name="maritalStatus"
                              checked={
                                values.maritalStatus === "Married"
                                  ? true
                                  : false
                              }
                              id="flexRadiomarried"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadiomarried"
                            >
                              Married
                            </label>
                          </div>

                          <div className="form-check form-check-custom form-check-solid form-check-sm">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="Unmarried"
                              onChange={(e: any) =>
                                handleChangeValue(e, "maritalStatus")
                              }
                              onBlur={handleBlur}
                              checked={
                                values.maritalStatus === "Unmarried"
                                  ? true
                                  : false
                              }
                              name="maritalStatus"
                              id="flexRadiounmarried"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadiounmarried"
                            >
                              Unmarried
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 mt-md-0">
              <div className="col-12 mt-md-0">
                <div className="card card-xl-stretch mb-5">
                  <div className="card-header border-0 py-3 py-md-2 px-5 px-md-7 align-items-center">
                    {/* begin::Title */}
                    <h3 className="card-label fw-bold font-17 mb-0">
                      Professional Details
                    </h3>
                  </div>
                  <div className="card-body pt-2">
                    <div className="row">
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <label className="form-label">
                          Role<span className="text-danger">*</span>
                        </label>
                        <AsyncPaginate
                          loadOptions={extendedRoleLoadOptions}
                          isSearchable
                          classNamePrefix="react-select"
                          value={roleDropDown}
                          theme={(theme: any) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#0c78a4",
                            },
                          })}
                          name="role"
                          id="role"
                          onChange={(e: any) => {
                            handleChangeValue(e, "role");
                            setRoleDropDown(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.role && touched.role ? (
                          <p className="form-error text-danger">
                            {errors.role}
                          </p>
                        ) : null}
                        <div>
                          <span className="font-14 text-dark"></span>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <label className="form-label">
                          Team <span className="text-danger">*</span>
                        </label>

                        <AsyncPaginate
                          loadOptions={extendedTeamLoadOptions}
                          isSearchable
                          //className='react-select-container my-1 me-3 w-150px'
                          classNamePrefix="react-select"
                          value={teamMember}
                          // onChange={(e: any) => handleChange(e, 'warehouse')}
                          theme={(theme: any) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#0c78a4",
                            },
                          })}
                          name="team"
                          id="team"
                          onChange={(e: any) => {
                            handleChangeValue(e, "team");
                            setTeamMember(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.team && touched.team ? (
                          <p className="form-error text-danger">
                            {errors.team}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <label className="form-label">
                          Job Role Type
                          <span className="text-danger">*</span>
                        </label>
                        <AsyncPaginate
                          loadOptions={extendedJobRoleLoadOptions}
                          isSearchable
                          classNamePrefix="react-select"
                          value={jobRoleDropDown}
                          theme={(theme: any) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#0c78a4",
                            },
                          })}
                          name="jobRoleType"
                          id="jobRoleType"
                          onChange={(e: any) => {
                            handleChangeValue(e, "jobRoleType");
                            setJobRoleDropDown(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.jobRoleType && touched.jobRoleType ? (
                          <p className="form-error text-danger">
                            {errors.jobRoleType}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <label className="form-label">
                          Work Location
                          <span className="text-danger">*</span>
                        </label>

                        <AsyncPaginate
                          loadOptions={extendedWorkLocationLoadOptions}
                          isSearchable
                          classNamePrefix="react-select"
                          value={workLocationDropDown}
                          theme={(theme: any) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#0c78a4",
                            },
                          })}
                          name="workLocation"
                          id="workLocation"
                          onChange={(e: any) => {
                            handleChangeValue(e, "workLocation");
                            setWorkLocationDropDown(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.workLocation && touched.workLocation ? (
                          <p className="form-error text-danger">
                            {errors.workLocation}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-sm-6 col-xl-4">
                        <label className="form-label">
                          Tech stack <span className="text-danger">*</span>
                        </label>
                        <AsyncPaginate
                          loadOptions={extendedTechStockLoadOptions}
                          isSearchable
                          isMulti
                          classNamePrefix="react-select"
                          value={techStack}
                          theme={(theme: any) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#0c78a4",
                            },
                          })}
                          name="techStack"
                          id="techStack"
                          onChange={(e: any) => {
                            handleChangeValue(e, "techStack");
                            setTechStack(e);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.techStack && touched.techStack ? (
                          <p className="form-error text-danger">
                            {errors.techStack}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-sm-6 col-xl-4">
                        <label className="form-label">Library </label>
                        <CreatableSelect
                          isMulti
                          options={libraryData}
                          value={library}
                          onChange={(e: any) => {
                            handleChangeValue(e, "library");
                            setLibrary(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-xl-4">
                        <label className="form-label">
                          Total Number of Experience
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Total Number of Experience"
                          name="experience"
                          id="experience"
                          value={values.experience}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              handleChange(e);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="col-sm-6 col-xl-4">
                        <label className="form-label">Joining Date</label>
                        <DateRangePicker
                          onCallback={(start: any, end: any, label: any) => {
                            let newDate = start._d;
                            values["joiningDate"] =
                              moment(newDate).format("YYYY-MM-DD");
                            //setJoinDate(newDate.toLocaleString());
                          }}
                          initialSettings={{
                            startDate: values.joiningDate,
                            endDate: values.joiningDate,
                            minYear: "1901",
                            maxDate: moment(),
                            showDropdowns: true,
                            alwaysShowCalendars: true,
                            cancelClass: "btn-secondary",
                            singleDatePicker: true,
                            drops: "auto",
                            locale: {
                              format: "MM/DD/YYYY",
                            },
                          }}
                        >
                          <input
                            type="text"
                            name="joiningDate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // value={values.joiningDate}
                            id="joiningDate"
                            className="form-control calendar"
                          />
                        </DateRangePicker>
                      </div>
                      <div className="col-sm-6 col-xl-4">
                        <label className="form-label">Last Working Day</label>
                        <DateRangePicker
                          onCallback={(start: any, end: any, label: any) => {
                            let newDate = start._d;
                            values["lastWorkingDay"] =
                              moment(newDate).format("YYYY-MM-DD");
                          }}
                          initialSettings={{
                            startDate: isEmpty(values.lastWorkingDay)
                              ? moment()
                              : values.lastWorkingDay,
                            endDate: isEmpty(values.lastWorkingDay)
                              ? moment()
                              : values.lastWorkingDay,
                            alwaysShowCalendars: true,
                            cancelClass: "btn-secondary",
                            minYear: "1901",
                            maxYear: moment().format("YYYY"),
                            showDropdowns: true,
                            singleDatePicker: true,
                            drops: "auto",

                            locale: {
                              format: "MM/DD/YYYY",
                            },
                          }}
                        >
                          <input
                            type="text"
                            name="lastWorkingDay"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // value={values.lastWorkingDay}
                            id="lastWorkingDay"
                            className="form-control calendar"
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* chart end */}

            {/* chart start */}

            <div className="row mt-3 mt-md-0">
              <div className="col-12 mt-md-0">
                <div className="card card-xl-stretch mb-5">
                  <div className="card-header border-0 py-3 py-md-2 px-5 px-md-7 align-items-center">
                    {/* begin::Title */}
                    <h3 className="card-label fw-bold font-17 mb-0">
                      Communication Details
                    </h3>
                    {/* end::Title */}
                  </div>
                  <div className="card-body pt-2">
                    <div className="row">
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <label className="form-label">Personal Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Personal Email"
                          name="personalEmail"
                          id="personalEmail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.personalEmail}
                        />
                        {errors.personalEmail && touched.personalEmail ? (
                          <p className="form-error text-danger">
                            {errors.personalEmail}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <label className="form-label">Company Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company Email"
                          name="companyEmail"
                          id="companyEmail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={checkEmpty(values.companyEmail, "")}
                        />
                        {errors.companyEmail && touched.companyEmail ? (
                          <p className="form-error text-danger">
                            {errors.companyEmail}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <label className="form-label">
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phono Number"
                          name="phoneNumber"
                          id="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phoneNumber && touched.phoneNumber ? (
                          <p className="form-error text-danger">
                            {errors.phoneNumber}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* chart end */}

            <div className="row mt-3 mt-md-0">
              <div className="col-12 mt-md-0">
                <div className="card card-xl-stretch mb-5">
                  <div className="card-header border-0 py-3 py-md-2 px-5 px-md-7 align-items-center">
                    {/* begin::Title */}
                    <h3 className="card-label fw-bold font-17 mb-0">
                      Other Details
                    </h3>
                    {/* end::Title */}
                  </div>
                  <div className="card-body pt-2">
                    <div className="row">
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <div className="d-flex flex-wrap flex-sm-nowrap align-items-center mb-5">
                          <div className="me-3 me-md-4 mb-3">
                            <div className="symbol symbol-60px symbol-lg-100px symbol-fixed">
                              <img src={checkImage(image)} alt="Not Found" />
                            </div>
                          </div>
                          <div className="position-relative form-file">
                            <div className="font-14">{"Profile Picture"}</div>
                            <label
                              className="form-label mb-0"
                              htmlFor="group-icon"
                            >
                              <button
                                type="button"
                                className="btn btn-bg-light btn-sm btn-icon-dark btn-text-dark mb-0 py-1"
                              >
                                <span className="svg-icon svg-icon-1">
                                  <KTSVG
                                    path="/media/icons/duotune/files/fil022.svg"
                                    className="ic svg-icon-muted me-1"
                                  />
                                </span>
                                Upload Photo
                              </button>
                            </label>
                            <input
                              type="file"
                              className="file-input opacity-0 position-absolute h-auto"
                              placeholder="Type here..."
                              id="group-icon"
                              name="profilePic"
                              onChange={(e: any) =>
                                handleChangeValue(e, "profilePic")
                              }
                              onBlur={handleBlur}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <label className="form-label">Short Description</label>
                        <textarea
                          className="form-control"
                          id="shortDescription"
                          name="shortDescription"
                          value={values.shortDescription}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Short Description"
                        ></textarea>
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-6">
                        <div
                          style={{
                            display: handler === "edit" ? "block" : "none",
                          }}
                          className="col-lg-6 fv-row mb-4"
                        >
                          <label className="form-label">Status</label>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <label className="form-check-label me-3">
                              Access Status
                            </label>
                            <input
                              className="form-check-input"
                              id="status"
                              name="status"
                              type="checkbox"
                              value=""
                              checked={
                                values.status === "true" ||
                                values.status === true
                              }
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* begin::Body */}
            <div className="card-footer py-4 text-center">
              <button
                onClick={() => navigate("/team-members")}
                type="button"
                disabled={submit !== "Submit"}
                className="btn btn-secondary me-3"
              >
                Cancel
              </button>
              <button
                disabled={submit !== "Submit"}
                type="submit"
                className="btn btn-primary"
              >
                {submit}
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default AddTeamMembers;
