export const TEAM_LIST = "teamList";
export const WORK_LOCATION = "workLocationList";
export const EDUCATION = "educationList";
export const JOBROLE_LIST = "jobRoleList";
export const ROLE_LIST = "rolesList";
export const TEAM_DETAIL = "teams/$id$";
export const STORE = "user/adduser";
export const TEAM_MEMBER_LIST = "user/list";
export const TEAM_MEMBER_GET = "user/view";
export const TEAM_MEMBER_UPDATE = "user/update";
export const TEAM_MEMBER_DELETE = "user/delete/";
export const SET_PASSWORD_LINK_GENERATOR = "set-password-link-generator";
export const TEAM_MEMBER_PROFILE = "user/profile";
export const USER_UPDATE = "user/update-profile";
export const TECH_STACK = "techStack";
export const LIBRARY = "library";
