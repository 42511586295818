import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { ThreeDots } from "react-loader-spinner";

const FetchingRecords = () => {
  const [dots, setDots] = useState<string>(".");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (dots.length === 3) {
        setDots("");
      } else {
        setDots(dots + ".");
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [dots]);

  return (
    <>
      <div className="text-center mb-5 mt-3">
        <img
          src={toAbsoluteUrl("/media/smiles/img-fetching-data.png")}
          alt="Eafruits"
        />
        <div
          className="text-center mb-5"
          style={{
            justifyContent: "center",
            display: "flex",
            position: "relative",
            left: 0,
            right: 0,
            bottom: 0,
            width: "100% !important",
            alignItems: "center",
            background: "rgba(255, 255, 355, 0.7)",
            height: "100% !important",
            zIndex: 99,
          }}
        >
          <ThreeDots color="#0c78a4" height={30} width={30} />
        </div>
      </div>
    </>
  );
};

export default FetchingRecords;
