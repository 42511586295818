import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_mis/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_mis/assets/ts/_utils";
import TeamMembers from "../pages/teamMembers/teamMembers";
import { DashboardWrapper } from "../pages/dashboard/dashboardWrapper";
import TeamMemberName from "../pages/teamMembers/teamMemberName/teamMemberName";
import Teams from "../pages/masters/teams/teams";
import JobRoleType from "../pages/masters/jobRoleType/jobRoleType";
import WorkLocation from "../pages/masters/workLocation/workLocation";
import RolesPermission from "../pages/masters/rolesPermission/rolesPermission";
import AddteamMembers from "../pages/teamMembers/addTeammember";
import LeaveSummary from "../pages/leaveSummary/leaveSummary";
import LeaveDetails from "../pages/leaveSummary/leaveDetails";
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />{" "}
        {/*  DASHBOARD */}
        <Route path="team-members/*" element={<TeamMembers />} />{" "}
        {/*  Teammembers PAGE */}
        <Route
          path="team-members/add-teammember"
          element={<AddteamMembers />}
        />{" "}
        {/*  Teammembers PAGE */}
        <Route
          path="team-members/edit-teammember/:id"
          element={<AddteamMembers />}
        />{" "}
        {/*  Teammembers PAGE */}
        <Route
          path="team-members/team-member-name/*"
          element={<TeamMemberName />}
        />{" "}
        {/*  Teammembers /team-member-name */}
        <Route path="leaves/*" element={<LeaveSummary />} />{" "}
        {/*  Leaves Summery PAGE */}
        <Route path="leaves/leave-details" element={<LeaveDetails />} />{" "}
        {/*  Leave Details PAGE */}
        {/* Super admin REPORTS WITH SUBMENU */}
        {/* <Route path="masters/*">
          
          <Route path="teams" element={<Teams className="teams" />} />
          <Route
            path="job-role-type"
            element={<JobRoleType className="teams" />}
          />
          <Route
            path="work-location"
            element={<WorkLocation className="teams" />}
          />
          <Route
            path="roles-permission"
            element={<RolesPermission className="teams" />}
          />
          <Route path="*" element={<Navigate to="/masters/teams" />} />
        </Route> */}
        {/* Lazy Modules */}
        <Route
          path="profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
