import React from "react";
import { toAbsoluteUrl } from "../../../helpers";

const NoRecords = () => {
  return (
    <>
      {/* <div className="text-center mb-5">
                <img src={toAbsoluteUrl('/media/smiles/error_graphic_one.png')} alt='Eafruits' />
                <p className="fw-600">You have no brands created</p>
                <EaButton isModal={true} dataTarget="#ea_modal_create_brand" btnName="Add Brand" />
            </div> */}
      <div className="text-center mb-5">
        <img
          src={toAbsoluteUrl("/media/smiles/error_graphic_one.png")}
          alt="Eafruits"
        />
        {/* <p className="fw-600">No records found. Please try again with different keyword.</p> */}
        <p className="fw-600">No records found !</p>
      </div>
    </>
  );
};

export default NoRecords;
