import React, { useEffect, useState } from "react";
import EaButton from "../../../../../_mis/layout/components/button/eaButton";
import { Button } from "react-bootstrap";
import { TEAM_MEMBER_GET } from "../../utils/TeamMemberAPIs";
import { getRequest } from "../../../../../_mis/helpers/APIHelper";
import { useNavigate } from "react-router-dom";
import IsLoader from "../../../../../_mis/layout/components/loader/loader";

const Overview = () => {
  const navigate = useNavigate();
  const id = localStorage.getItem("team-id");

  const [teamData, setTeamData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTeamMemberDetails = async () => {
    setIsLoading(true);
    const url = TEAM_MEMBER_GET + "/" + id;
    const response = await getRequest(navigate, url, true);
    setTeamData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTeamMemberDetails();
  }, []);

  return (
    <>
      {isLoading ? (
        <IsLoader />
      ) : (
        <>
          {/* chart start */}
          <div className="row mt-3 mt-md-0">
            <div className="col-12 mt-md-0">
              <div className="card card-xl-stretch mb-5">
                <div className="card-header border-0 py-3 py-md-2 px-5 px-md-7 align-items-center">
                  {/* begin::Title */}
                  <h3 className="card-label fw-bold font-17 mb-0">
                    Professional Details
                  </h3>
                </div>
                <div className="card-body pt-2">
                  <div className="row">
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">Role</label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData?.roles?.user_role_detail?.name
                            ? teamData?.roles?.user_role_detail?.name
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">Team</label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData?.department?.department_detail?.name
                            ? teamData?.department?.department_detail?.name
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">
                        Joining Date
                      </label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData?.working_detail?.work_start_date
                            ? teamData?.working_detail?.work_start_date
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">
                        Job Role Type
                      </label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData?.job_role?.job_role_type_detail?.name
                            ? teamData?.job_role?.job_role_type_detail?.name
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">
                        Work Location
                      </label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData?.work_location?.user_work_location_detail
                            ?.name
                            ? teamData?.work_location?.user_work_location_detail
                                ?.name
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">
                        Total Work Experience
                      </label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData.dob ? teamData.dob : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-xl-4">
                      <label className="font-14 text-muted mb-2">
                        Education
                      </label>
                      <div>
                        <Button variant="secondary" size="sm" className="me-2">
                          {teamData.education &&
                          teamData.education.user_education_detail
                            ? teamData.education.user_education_detail.name
                            : "-"}
                        </Button>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4">
                      <label className="font-14 text-muted mb-2">
                        Tech Stack
                      </label>
                      <div>
                        {teamData.tech_stack &&
                          teamData.tech_stack.length > 0 &&
                          teamData.tech_stack.map((item: any) => {
                            return (
                              <Button
                                variant="secondary"
                                size="sm"
                                className="me-2"
                              >
                                {item.user_tech_stack_detail.name}
                              </Button>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4">
                      <label className="font-14 text-muted mb-2">Library</label>
                      <div>
                        {teamData.user_libraries &&
                          teamData.user_libraries.length > 0 &&
                          teamData.user_libraries.map((item: any) => {
                            return (
                              <Button
                                variant="secondary"
                                size="sm"
                                className="me-2"
                              >
                                {item.user_library_detail.name}
                              </Button>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* chart end */}

          {/* chart start */}
          <div className="row mt-3 mt-md-0">
            <div className="col-12 mt-md-0">
              <div className="card card-xl-stretch mb-5">
                <div className="card-header border-0 py-3 py-md-2 px-5 px-md-7 align-items-center">
                  {/* begin::Title */}
                  <h3 className="card-label fw-bold font-17 mb-0">
                    Personal Details
                  </h3>
                  {/* end::Title */}
                </div>
                <div className="card-body pt-2">
                  <div className="row">
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">
                        Date of Birth
                      </label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData.dob ? teamData.dob : "-"}
                        </span>
                        {/* <span>sjd</span> */}
                        {/* 08 Jul 1991 */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">Gender</label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData.gender ? teamData.gender : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">
                        Merital Status
                      </label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData.marital_status
                            ? teamData.marital_status
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* chart end */}

          {/* chart start */}
          <div className="row mt-3 mt-md-0">
            <div className="col-12 mt-md-0">
              <div className="card card-xl-stretch mb-5">
                <div className="card-header border-0 py-3 py-md-2 px-5 px-md-7 align-items-center">
                  {/* begin::Title */}
                  <h3 className="card-label fw-bold font-17 mb-0">
                    Communication Details
                  </h3>
                  {/* end::Title */}
                </div>
                <div className="card-body pt-2">
                  <div className="row">
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">
                        Phone Number
                      </label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData.phone_number ? teamData.phone_number : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">
                        Alternate Phone Number
                      </label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData.alternate_phone_number
                            ? teamData.alternate_phone_number
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">
                        Company Email
                      </label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData.email ? teamData.email : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-6">
                      <label className="font-14 text-muted mb-2">
                        Personal Email
                      </label>
                      <div>
                        <span className="font-14 text-dark">
                          {teamData.personal_email
                            ? teamData.personal_email
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                <div className="col-sm-6 col-xl-4 mb-6">
                  <label className="font-14 text-muted mb-2">
                    Permanent Address
                  </label>
                  <div>
                    <span className="font-14 text-dark">
                      {teamData.address ? teamData.address : "-"}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-xl-4 mb-6">
                  <label className="font-14 text-muted mb-2">
                    Temporary Address
                  </label>
                  <div>
                    <span className="font-14 text-dark">
                      {teamData.address ? teamData.address : "-"}
                    </span>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* chart end */}
        </>
      )}
    </>
  );
};

export default Overview;
