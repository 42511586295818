import { toAbsoluteUrl } from "../AssetHelpers";

export const GLOBAL_LIMIT = process.env.REACT_APP_DEFAULT_LIMIT || 25;

export const getToken = () => {
  const users: any = localStorage.getItem("kt-auth-react-v");
  const data = JSON.parse(users);
  return data && data.token;
};

// handling validation
export const isModalValidation = (list: any) => {
  let validation: any = [];
  for (var key in list) {
    if (list[key] === "" || list[key] === null) {
      validation.push(key);
    }
  }
  return validation;
};

// handling formdata
export const formDataConverter = (list: any, removeKeyList?: any) => {
  let formData = new FormData();

  Object.entries(list).map((object: any) => {
    if (!removeKeyList.includes(object[0])) {
      return formData.append(object[0], object[1]);
    }
  });

  return formData;
};

export const checkEmpty = (data: any, defaultReturnValue?: string) => {
  if (["", null, "null", undefined].includes(data)) {
    if (defaultReturnValue || defaultReturnValue === "") {
      return defaultReturnValue;
    }

    return "-";
  }

  return data;
};

export const isEmpty = (data: any) => {
  if (data === undefined || data === null || data === "null") {
    return true;
  }

  if (typeof data === "object") {
    if (Object.keys(data).length === 0) {
      return true;
    }
  }

  if (typeof data === "string") {
    return [""].includes(data.trim());
  }
};

export const checkValue = (value: any) => {
  if (value === "" || value === undefined || value === null) {
    return "-";
  }
  return value;
};

export const checkImage = (value: any) => {
  if (value === "" || value === undefined || value === null) {
    return toAbsoluteUrl("/media/avatars/img_user.png");
  }
  return value;
};

export const isLogout = (navigate: any, err: any) => {
  if (err.response.status === 401) {
    localStorage.clear();
    window.location.reload();
    navigate("/");
  }
};
