import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link, useNavigate } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../../_mis/helpers'
import { getRequest } from '../../../../_mis/helpers/APIHelper';
// import { TEAM_MEMBER_LIST } from '../utils/TeamMemberAPIs';

const TableLeaveSummary = () => {
    const navigate = useNavigate();
    const navigateToTeam = () => {
        navigate('/leaves')
    }

    return (
        <>
        {/* begin::Table */}
        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-150px'>Employee Name</th>
                                        <th className='min-w-140px'>Subject</th>
                                        <th className='min-w-120px'>Applied On</th>
                                        <th className='min-w-140px'>Leave Status</th>

                                        <th className='min-w-80px text-end'>Actions</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                <tr>
                                        <td className=''>Ami Patel</td>
                                        <td className=''>Application for Casual Leaves</td>
                                        <td className=''>20/3/2023</td>
                                        
                                        <td className=''>
                                        <span className="badge badge-light-success fs-8 black-clr fw-500">Approved</span>
                                        </td>
                                        <td className='text-end'>
                                            <a onClick={() => navigate('leave-details')}
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/ic-view.svg' className='ic mr-0' />
                                            </a>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>Kaushal Panchal</td>
                                        <td className=''>Application for Travel Out of Station</td>
                                        <td className=''>10/3/2023</td>
                                        
                                        <td className=''>
                                        <span className="badge badge-light-warning fs-8 black-clr fw-500">Pending</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/ic-view.svg' className='ic mr-0' />
                                            </a>
                                            
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className=''>Kaushal Panchal</td>
                                        <td className=''>Application for Travel Out of Station</td>
                                        <td className=''>10/3/2023</td>
                                        
                                        <td className=''>
                                        <span className="badge badge-light-danger fs-8 black-clr fw-500">Rejected</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/ic-view.svg' className='ic mr-0' />
                                            </a>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>Kaushal Panchal</td>
                                        <td className=''>Application for Travel Out of Station</td>
                                        <td className=''>10/3/2023</td>
                                        
                                        <td className=''>
                                        <span className="badge badge-light-success fs-8 black-clr fw-500">Approved</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/ic-view.svg' className='ic mr-0' />
                                            </a>
                                            
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className=''>Kaushal Panchal</td>
                                        <td className=''>Application for Travel Out of Station</td>
                                        <td className=''>10/3/2023</td>
                                        
                                        <td className=''>
                                        <span className="badge badge-light-danger fs-8 black-clr fw-500">Rejected</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/ic-view.svg' className='ic mr-0' />
                                            </a>
                                            
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className=''>Kaushal Panchal</td>
                                        <td className=''>Application for Travel Out of Station</td>
                                        <td className=''>10/3/2023</td>
                                        
                                        <td className=''>
                                        <span className="badge badge-light-warning fs-8 black-clr fw-500">Pending</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/ic-view.svg' className='ic mr-0' />
                                            </a>
                                            
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className=''>Kaushal Panchal</td>
                                        <td className=''>Application for Travel Out of Station</td>
                                        <td className=''>10/3/2023</td>
                                        
                                        <td className=''>
                                        <span className="badge badge-light-success fs-8 black-clr fw-500">Approved</span>
                                        </td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/ic-view.svg' className='ic mr-0' />
                                            </a>
                                            
                                        </td>
                                    </tr>
                                  
                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
        </>

)
}


export default TableLeaveSummary