import React from "react";
import { ThreeDots } from "react-loader-spinner";

const IsLoader = () => {
  return (
    <div
      className="text-center mb-5"
      style={{
        justifyContent: "center",
        display: "flex",
        position: "absolute",
        left: 0,
        right: 0,
        top: "65px",
        bottom: 0,
        width: "100% !important",
        alignItems: "center",
        background: "rgba(255, 255, 355, 0.7)",
        height: "100% !important",
        zIndex: 99,
      }}
    >
      {/* <ThreeDots color="#0c78a4" height={80} width={80} /> */}
      <div
        style={{ width: "4rem", height: "4rem", color: "#0c78a4" }}
        className="text-center"
      >
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
    // <div id="splash-screen1" className="splash-screen-loader">
    //     {/* <!-- <img src="%PUBLIC_URL%/media/logos/Spin-1s-200px.gif" alt="EAfruits logo" class="" /> --> */}
    //     <img src="media/logos/logolight2.png" alt="EAfruits logo" className="h-55px" />
    //     <span className='mb-5'>Loading ...</span>
    // </div>
  );
};

export default IsLoader;
