/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from "react";
import { Link } from "react-router-dom";
import ChangePassword from "../../../layout/components/header/changePassword";
import { checkImage, checkValue } from "../../../helpers/utils/CommonHelpers";

const HeaderUserMenu = (props: any) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { profile, first_name, last_name, roles } = props.user;

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg text-dark menu-gray-900 font-13 pt-4 w-200px"
        data-kt-menu="true"
      >
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-2 pt-1 pb-3">
            <div className="symbol symbol-36px me-5">
              <img src={checkImage(profile)} alt="Mis Moweb" />
            </div>
            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center font-13">
                {first_name} {last_name}
              </div>
              <a
                href="javascript:void(0)"
                className="text-muted text-hover-primary font-13"
              >
                {roles &&
                  roles.user_role_detail &&
                  checkValue(roles.user_role_detail.name)}
              </a>
            </div>
          </div>
        </div>

        <div className="separator my-0"></div>

        <div className="menu-item">
          <Link to={"/profile/myprofile"} className="menu-link px-5 rounded-0">
            My Account
          </Link>
        </div>
        <div className="separator my-0"></div>
        <div className="menu-item">
          <a
            href="#"
            className="menu-link px-5 rounded-0"
            onClick={() => setModalShow(true)}
          >
            Change Password
          </a>
          <ChangePassword show={modalShow} onHide={() => setModalShow(false)} />
        </div>

        <div className="separator my-0"></div>

        <div className="menu-item">
          <a onClick={logOut} className="menu-link px-5 rounded-0">
            Sign Out
          </a>
        </div>
      </div>
    </>
  );
};

export { HeaderUserMenu };
