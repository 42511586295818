import * as Yup from "yup";

export const teamMemberSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Please enter minimum 2 characters")
    .max(25, "Please enter maximum 25 characters")
    .required("Please enter your first name"),
  lastName: Yup.string()
    .min(2, "Please enter minimum 2 characters")
    .max(25, "Please enter maximum 25 characters")
    .required("Please enter your last name"),
  phoneNumber: Yup.string()
    .min(10, "Please enter minimum 10 Digit")
    .max(10, "Please enter maximum 10 Digit")
    .required("Please enter your phone number"),
  gender: Yup.string().required("Please select your gender"),
  personalEmail: Yup.string().email("Wrong email format"),
  companyEmail: Yup.string().email("Wrong email format"),
  team: Yup.string().required("Please select your team"),
  techStack: Yup.string().required("Please select your tech stack"),
  workLocation: Yup.string().required("Please select your work location"),
  jobRoleType: Yup.string().required("Please select your job role type"),
  role: Yup.string().required("Please select your role"),
});
