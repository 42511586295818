import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_mis/helpers'
import { useNavigate } from 'react-router-dom'
import EaButton from '../../../_mis/layout/components/button/eaButton'
import EaModal from '../../../_mis/layout/components/modal/eaModal'
import CustomPagination from '../../../_mis/layout/components/pagination/pagination'
import CustomSearchBar from '../../../_mis/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../_mis/layout/core'
import Select from 'react-select'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import TableLeaveSummary from './components/tableLeaveSummary'
import { Button, Modal } from 'react-bootstrap'

const total = 24;
const remaining = 16;

const duration = [
    { value: 'option', label: 'Option' },
    { value: 'option', label: 'Option' }
]

const leavetype = [
    { value: 'option', label: 'Option' },
    { value: 'option', label: 'Option' }
]

const leavesubtype = [
    { value: 'option', label: 'Option' },
    { value: 'option', label: 'Option' }
]

const statusoptions = [
    { value: 'allstatus', label: 'All Status' },
    { value: 'option', label: 'Option' },
    { value: 'option', label: 'Option' },
    { value: 'option', label: 'Option' },
]

const monthoptions = [
    { value: 'allmonth', label: 'All Month' },
    { value: 'option', label: 'Option' },
    { value: 'option', label: 'Option' },
    { value: 'option', label: 'Option' },
]

const yearoptions = [
    { value: 'allyear', label: 'All Year' },
    { value: 'option', label: 'Option' },
    { value: 'option', label: 'Option' },
    { value: 'option', label: 'Option' },
]

const LeaveSummary = () => {
    const [value, onChange] = useState(new Date());
    const navigate = useNavigate()
    const intl = useIntl()
    const headerTitle = {
        title: "Leaves",
        subTitle: "Leaves"
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [option, setOption] = useState('1')

    return (
        <>

            <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({ id: 'Leaves' })}</PageTitle>
            <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-4 px-5 px-md-7'>
                            {/* begin::Title */}
                            <div><h3 className="card-title align-items-start flex-column my-0">
                                <span className="card-label fw-bold font-17">Leaves Summary</span>
                            </h3>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-lg col-6 text-center mbres-20 mbipadres-20'>
                                <CircularProgressbar className='mb-4'
                                    value={(remaining/total)*100}
                                    text={`${remaining}`}
                                    styles={buildStyles({
                                        // Rotation of path and trail, in number of turns (0-1)
                                        rotation: 0.25,
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',
                                        // Text size
                                        textSize: '24px',
                                        // How long animation takes to go from one percentage to another, in seconds
                                        pathTransitionDuration: 0.5,
                                        textColor: '#000000',
                                        trailColor: '#D9D9D9',
                                        backgroundColor: '#05A6F0',
                                    })}
                                    />
                                    <p className="mb-0 font-18 fw-500">Annual Leave</p>
                                    <p className="font-16 fw-500 mb-0 text-muted">remaining</p>
                                </div>
                                <div className='col-lg col-6 text-center leave-green mbres-20 mbipadres-20'>
                                <CircularProgressbar className='mb-4'
                                    value={(remaining/total)*100}
                                    text={`${remaining}`}
                                    styles={buildStyles({
                                        // Rotation of path and trail, in number of turns (0-1)
                                        rotation: 0.25,
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',
                                        // Text size
                                        textSize: '24px',
                                        // How long animation takes to go from one percentage to another, in seconds
                                        pathTransitionDuration: 0.5,
                                        textColor: '#000000',
                                        trailColor: '#D9D9D9',
                                        backgroundColor: '#05A6F0',
                                    })}
                                    />
                                    <p className="mb-0 font-18 fw-500">Paid Leave</p>
                                    <p className="font-16 fw-500 mb-0 text-muted">taken</p>
                                </div>
                                <div className='col-lg col-6 text-center leave-orange mbres-20 mbipadres-20'>
                                <CircularProgressbar className='mb-4'
                                    value={(remaining/total)*100}
                                    text={`${remaining}`}
                                    styles={buildStyles({
                                        // Rotation of path and trail, in number of turns (0-1)
                                        rotation: 0.25,
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',
                                        // Text size
                                        textSize: '24px',
                                        // How long animation takes to go from one percentage to another, in seconds
                                        pathTransitionDuration: 0.5,
                                        textColor: '#000000',
                                        trailColor: '#D9D9D9',
                                        backgroundColor: '#05A6F0',
                                    })}
                                    />
                                    <p className="mb-0 font-18 fw-500">LWP</p>
                                    <p className="font-16 fw-500 mb-0 text-muted">taken</p>
                                </div>
                                <div className='col-lg col-6 text-center leave-purple mbres-20 mbipadres-20'>
                                <CircularProgressbar className='mb-4'
                                    value={(remaining/total)*100}
                                    text={`${remaining}`}
                                    styles={buildStyles({
                                        // Rotation of path and trail, in number of turns (0-1)
                                        rotation: 0.25,
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',
                                        // Text size
                                        textSize: '24px',
                                        // How long animation takes to go from one percentage to another, in seconds
                                        pathTransitionDuration: 0.5,
                                        textColor: '#000000',
                                        trailColor: '#D9D9D9',
                                        backgroundColor: '#05A6F0',
                                    })}
                                    />
                                    <p className="mb-0 font-18 fw-500">Compensate</p>
                                    <p className="font-16 fw-500 mb-0 text-muted">taken</p>

                                </div>
                                <div className='col-lg col-6 text-center leave-pink'>
                                <CircularProgressbar className='mb-4'
                                    value={(remaining/total)*100}
                                    text={`${remaining}`}
                                    styles={buildStyles({
                                        // Rotation of path and trail, in number of turns (0-1)
                                        rotation: 0.25,
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',
                                        // Text size
                                        textSize: '24px',
                                        // How long animation takes to go from one percentage to another, in seconds
                                        pathTransitionDuration: 0.5,
                                        textColor: '#000000',
                                        trailColor: '#D9D9D9',
                                        backgroundColor: '#05A6F0',
                                    })}
                                    />
                                    <p className="mb-0 font-18 fw-500">Maternity</p>
                                    <p className="font-16 fw-500 mb-0 text-muted">taken</p>
                                </div>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    <div className="ms-auto mt-res20">
                        <div className='card-toolbar d-flex my-0'>
                        <Select
                                options={yearoptions}
                                isSearchable
                                placeholder="All Years"
                                className='react-select-container my-1 me-3 w-120px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <Select
                                options={monthoptions}
                                isSearchable
                                placeholder="All Months"
                                className='react-select-container my-1 me-3 w-130px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                            <Select
                                options={statusoptions}
                                isSearchable
                                placeholder="All Status"
                                className='react-select-container my-1 me-3 w-125px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />
                             <button className="btn btn-sm btn-primary my-1" onClick={handleShow}>Apply Leave</button>
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        <TableLeaveSummary />
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div>
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} id="ea_modal_add_leavesummary" centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Leave Application  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                    <div className='row mb-5'>
                        <div className='col'>
                            <label className="form-label">Subject</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type here..."
                                />
                         </div>
                        </div>

                        <div className='row mb-5'>
                            
                        <div className='col mbres-20'>
                        <label className="form-label">Date Range </label>
                        <DateRangePicker
                            initialSettings={{ startDate: '01/01/2020', endDate: '01/15/2020' }}
                            >
                            <input type="text" className="form-control" />
                        </DateRangePicker>
                        
                        </div>
                    <div className='col mbres-20'>
                      <label className="form-label">Leave Type</label>
                       <Select
                           options={leavetype}
                           isSearchable
                           className='react-select-container'
                           classNamePrefix="react-select"
                           theme={(theme) => ({
                               ...theme,
                               colors: {
                                   ...theme.colors,
                                   primary: '#0c78a4',
                               },
                           })}
                       />
                       </div>
                       <div className='col'>                   
                        <label className="form-label">Leave Sub Type</label>
                        <Select
                            options={leavesubtype}
                            isSearchable
                            className='react-select-container'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                        </div>
                        <div className='col'>                
                        <label className="form-label">Duration</label>
                        <Select
                            options={duration}
                            isSearchable
                            className='react-select-container'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                        </div>
                        <div className='col-auto d-flex align-items-end'>
                        <a href='javascript:void(0)' className='cross-border'>
                            <KTSVG path='/media/ic-cross.svg' className='ic mr-0' />
                        </a>
                       </div>
                        </div>
                        <div className='row mb-5'>
                        <div className='col mbres-20'>
                        <label className="form-label">Date Range </label>
                        <DateRangePicker
                            initialSettings={{ startDate: '01/01/2020', endDate: '01/15/2020' }}
                            >
                            <input type="text" className="form-control" />
                        </DateRangePicker>
                        
                        </div>
                    <div className='col mbres-20'>                  
                       <label className="form-label">Leave Type</label>
                       <Select
                           options={leavetype}
                           isSearchable
                           className='react-select-container'
                           classNamePrefix="react-select"
                           theme={(theme) => ({
                               ...theme,
                               colors: {
                                   ...theme.colors,
                                   primary: '#0c78a4',
                               },
                           })}
                       />
                       </div>
                       <div className='col'>                    
                        <label className="form-label">Leave Sub Type</label>
                        <Select
                            options={leavesubtype}
                            isSearchable
                            className='react-select-container'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                        </div>
                        <div className='col'>                  
                        <label className="form-label">Duration</label>
                        <Select
                            options={duration}
                            isSearchable
                            className='react-select-container'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                        </div>
                        <div className='col-auto d-flex align-items-end'>
                        <a href='javascript:void(0)' className='plus-border'>
                            <KTSVG path='/media/ic-plus.svg' className='ic mr-0' />
                        </a>
                        </div>
                    </div>
                        <div className='row'>
                        <div className='col'>
                        <label className="form-label">
                        Reason
                  </label>
                  <textarea
                    className="form-control"               
                    placeholder="Type here..."
                  ></textarea>
                        </div>
                        </div>
                    </form>              
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <Button variant="secondary" onClick={handleClose} className="w-18">
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleClose} className="w-18">
                        Apply
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </>
    )
}

export default LeaveSummary
