export const API_URL = process.env.REACT_APP_API_URL
export const LOGIN_URL = "login"
export const FORGET_PASSWORD_URL = "forgot-password"
export const RESET_PASSWORD_URL = "reset-password"
export const CHANGE_PASSWORD_URL = "change-password"

 



