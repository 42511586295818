import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { postRequest } from "../../../../_mis/helpers/APIHelper";
import { RESET_PASSWORD_URL } from "../utils/AuthAPIs";
import { toast } from "react-hot-toast";

const initialValues = {
  email: "",
  newPassword: "",
  confirmPassword: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  newPassword: Yup.string()
    .min(8, "New Password should be minimum 8 characters long")
    .max(50, "Maximum 50 symbols")
    .required("New Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long, and must contain characters (a-z), digits (0-9), capitals(A-Z), and special characters (e.g. ~!@#$)"
    ),
  confirmPassword: Yup.string()
    .min(8, "New Password should be minimum 8 characters long")
    .max(50, "Maximum 50 symbols")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must be at least 8 characters long, and must contain characters (a-z), digits (0-9), capitals(A-Z), and special characters (e.g. ~!@#$)"
    )
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword")], "Password does not match"),
});

export function ResetPassword() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  // After backend url changes, I will update this token key. : kriti
  const token = queryParams.get("token");

  const [loading, setLoading] = useState(false);
  const [hasErrorsSuccuss, setHasErrorsSuccuss] = useState<boolean | undefined>(
    undefined
  );
  const [hasErrorsFailed, setHasErrorsFailed] = useState<boolean | undefined>(
    undefined
  );
  const [successStatus, setSuccessStatus] = useState<boolean | undefined>(
    undefined
  );

  const [errorMessageToken, setErrorMessageToken] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessagePasswordConfirm, setErrorMessagePasswordConfirm] =
    useState("");

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      let email = values.email;
      let newPassword = values.newPassword;
      let confirmPassword = values.confirmPassword;

      setLoading(true);
      setHasErrorsFailed(undefined);
      setHasErrorsSuccuss(undefined);
      const formData = {
        token: token,
        email: email,
        password: newPassword,
        password_confirmation: confirmPassword,
      };

      const res = await postRequest(navigate, RESET_PASSWORD_URL, formData);

      if (res.data.data.token) {
        setErrorMessageToken(res.data.data.token[0]);
      }
      if (res.data.data.email) {
        setErrorMessageEmail(res.data.data.email[0]);
      }
      if (res.data.data.password) {
        setErrorMessagePassword(res.data.data.password[0]);
      }
      if (res.data.data.password_confirmation) {
        setErrorMessagePasswordConfirm(res.data.data.password_confirmation[0]);
      }

      if (res.status === 200) {
        setHasErrorsSuccuss(true);
        setLoading(false);
        setSuccessStatus(true);
        navigate("/auth/login");
        toast.success("Password reset successfully.");
        formik.resetForm();
      } else {
        setHasErrorsFailed(true);
        setLoading(true);
        setSubmitting(false);
        setStatus("The login detail is incorrect");
      }
      setTimeout(() => {
        setHasErrorsFailed(false);
        setHasErrorsSuccuss(false);
      }, 4000);
    },
  });

  const errorHandler = () => {
    return (
      <div className="mt-4 mb-4">
        <span className="text-danger">{errorMessageToken}</span>
        <span className="text-danger">{errorMessageEmail}</span>
        <span className="text-danger">{errorMessagePassword}</span>
        <span className="text-danger">{errorMessagePasswordConfirm}</span>
      </div>
    );
  };

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Title */}
        <div className="text-start mb-6">
          <h1 className="text-dark font-17 font-bold mb-1">Set Password</h1>
        </div>
        {/* end::Title */}

        {hasErrorsFailed && (
          <div className="mb-lg-7 alert alert-danger">
            <div className="alert-text font-weight-bold">
              Sorry, looks like there are some errors detected, please try
              again.
            </div>
          </div>
        )}
        {hasErrorsSuccuss && (
          <div className="mb-7 bg-light-info p-4 rounded">
            <div className="text-info">
              Sent password set. Please check your email
            </div>
          </div>
        )}

        {/* begin::Form group */}
        <div className="fv-row mb-4">
          <label className="form-label text-dark">Email</label>
          <input
            type="email"
            placeholder="Type here.."
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container text-danger">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        <div className="fv-row mb-4">
          <label className="form-label text-dark">New Password</label>
          <input
            type="password"
            placeholder="Type here.."
            autoComplete="off"
            {...formik.getFieldProps("newPassword")}
            className={clsx(
              "form-control psw-input",
              {
                "is-invalid":
                  formik.touched.newPassword && formik.errors.newPassword,
              },
              {
                "is-valid":
                  formik.touched.newPassword && !formik.errors.newPassword,
              }
            )}
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div className="fv-plugins-message-container">
              <span role="alert" className="text-danger">
                {formik.errors.newPassword}
              </span>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className="fv-row mb-4">
          <label className="form-label text-dark">Confirm New Password</label>
          <input
            type="password"
            placeholder="Type here.."
            autoComplete="off"
            {...formik.getFieldProps("confirmPassword")}
            className={clsx(
              "form-control psw-input",
              {
                "is-invalid":
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword,
              },
              {
                "is-valid":
                  formik.touched.confirmPassword &&
                  !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className="fv-plugins-message-container">
              <span role="alert" className="text-danger">
                {formik.errors.confirmPassword}
              </span>
            </div>
          )}
        </div>
        {errorHandler()}
        {/* end::Form group */}
        {/* begin::Form group */}
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary fw-500 w-100"
        >
          <span className="indicator-label">Set Password</span>
          {loading && (
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        {successStatus && (
          <div className="text-center mt-2 text-primary">
            Your password has been changed successfully.{" "}
            <span
              style={{
                cursor: "pointer",
              }}
              className="font-bold text-decoration-underline"
              onClick={() => navigate("/auth/login")}
            >
              {" "}
              Back To Login.
            </span>
          </div>
        )}

        {/* end::Form group */}
      </form>
    </>
  );
}
