import { GLOBAL_LIMIT } from "../../../helpers/utils/CommonHelpers";

const ShowingCount = ({ data, selected, count }: any) => {
  let globalLimit: any = GLOBAL_LIMIT;
  const limitPerPage = parseInt(globalLimit);
  const limit = limitPerPage;

  return (
    <>
      Showing {data.length === 0 ? 0 : selected === 1 ? 1 : selected * limit} to{" "}
      {data.length === 0 ? 0 : data.length < limit ? count : selected * limit}{" "}
      of {data.length === 0 ? 0 : count} entries
    </>
  );
};

export default ShowingCount;
