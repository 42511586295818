import { MODAL_SHOW_HIDE, MODAL_VALUE } from "../../type/type";

const initialState: any = {
  isModal: false,
  modalState: { url: "", response: false },
};

export const manageDeleteModal = (state = initialState, action: any) => {
  switch (action.type) {
    case MODAL_SHOW_HIDE:
      return {
        ...state,
        isModal: !state.isModal,
      };
    case MODAL_VALUE:
      return {
        ...state,
        modalState: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
