import ReactDOM from "react-dom";
// Axios
import axios from "axios";
import { Chart, registerables } from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";
//import {ReactQueryDevtools} from 'react-query/devtools'

import "./_mis/assets/sass/style.scss";
import "./_mis/assets/sass/style.react.scss";
import { AppRoutes } from "./app/routing/appRoutes";
import { AuthProvider, setupAxios } from "./app/modules/auth";
import { EafruitsI18nProvider } from "./_mis/i18n/Eafruits18n";
import { Toaster } from "react-hot-toast";

setupAxios(axios);

Chart.register(...registerables);

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Toaster
      toastOptions={{
        success: {
          style: {
            background: "green",
            color: "white",
          },
        },
        error: {
          style: {
            background: "red",
            color: "white",
          },
        },
      }}
    />
    <EafruitsI18nProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </EafruitsI18nProvider>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>,
  document.getElementById("root")
);
