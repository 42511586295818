import axios from "axios";
import { getToken, isLogout } from "./utils/CommonHelpers";
import { API_URL } from "../../app/modules/auth/utils/AuthAPIs";

const header = {
  headers: {
    Authorization: `Bearer ${getToken()}`,
    "Access-Control-Allow-Origin": "*",
  },
};

//navigate: It is for to redirect to any url on certain condition like redirect to log in if 403 code received

export const postRequest = async (
  navigate: any,
  URL: any,
  formData: any,
  token?: any
) => {
  let error = null;
  const postRequestUrl = `${API_URL}/${URL}`;
  const response: any = await axios
    .post(postRequestUrl, formData, token && header)
    .catch((e) => {
      isLogout(navigate, e);
      error = e.response;
    });

  if (error !== null) return error;

  return response;
};

export const getRequest = async (navigate: any, URL: any, token?: any) => {
  let error: any = null;
  const getRequestUrl = `${API_URL}/${URL}`;
  const response: any = await axios
    .get(getRequestUrl, token && header)
    .catch((e) => {
      isLogout(navigate, e);
      e = error;
    });

  if (error !== null) return error;

  return response.data;
};

export const putRequest = async (
  navigate: any,
  URL: any,
  formData: any,
  token?: any
) => {
  let error = null;
  const putRequestUrl = `${API_URL}/${URL}`;
  const response: any = await axios
    .put(putRequestUrl, formData, token && header)
    .catch((e) => {
      isLogout(navigate, e);
      error = e.response;
    });

  if (error !== null) return error;

  return response;
};

export const patchRequest = async (
  navigate: any,
  URL: any,
  formData: any,
  token?: any
) => {
  let error = null;
  const patchRequestUrl = `${API_URL}/${URL}`;
  const response: any = await axios
    .patch(patchRequestUrl, formData, token && header)
    .catch((e) => {
      isLogout(navigate, e);
      error = e.response;
    });

  if (error !== null) return error;

  return response;
};

export const deleteRequest = async (navigate: any, URL: any, token?: any) => {
  let error = null;
  const deleteRequestUrl = `${API_URL}/${URL}`;
  const response: any = await axios
    .delete(deleteRequestUrl, token && header)
    .catch((e) => {
      isLogout(navigate, e);
      error = e.response;
    });

  if (error !== null) return error;

  return response;
};
