import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { isEmpty } from "../../../../_mis/helpers/utils/CommonHelpers";

const ShowTechToolTip = ({ data, count }: any) => {
  let values: any = [];

  if (!isEmpty(data)) {
    values = data.split(",");
  }

  if (count > 0) {
    if (values && values.length > 0) {
      if (values.length > 1) {
        return (
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
              <Tooltip {...props} className="tooltip-dark">
                {values.map((item: any, index: any) => {
                  return (
                    <>
                      {index !== 0 && (
                        <span>
                          {index > 1 ? "," : ""}
                          {item}
                        </span>
                      )}
                    </>
                  );
                })}
              </Tooltip>
            )}
            placement="top"
          >
            <span className="ms-2 cursor-pointer">
              {values[0]}
              <span className="text-primary">{` & ${
                values.length - 1
              } more`}</span>
            </span>
          </OverlayTrigger>
        );
      } else {
        return <span className="ms-2 cursor-pointer">{values[0]}</span>;
      }
    } else {
      return <></>;
    }
  } else {
    return <span className="ms-2">-</span>;
  }
};

export default ShowTechToolTip;
