import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { postRequest } from "../../../../_mis/helpers/APIHelper";
import { FORGET_PASSWORD_URL } from "../utils/AuthAPIs";

const initialValues = {
  email: "admin@demo.com",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
});

export function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isButton, setIsButton] = useState("Submit");
  const [hasErrorsSuccuss, setHasErrorsSuccuss] = useState<boolean | undefined>(
    undefined
  );
  const [hasErrorsFailed, setHasErrorsFailed] = useState<boolean | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setIsButton("Please Wait");
      setHasErrorsFailed(undefined);
      setHasErrorsSuccuss(undefined);
      const formData = {
        email: values.email,
      };
      const res = await postRequest(navigate, FORGET_PASSWORD_URL, formData);
      setIsButton("Submit");
      if (res.status === 200) {
        if (res.data.code) {
          setHasErrorsSuccuss(true);
          setErrorMessage(res.data.message);
        } else {
          setHasErrorsFailed(true);
          setErrorMessage(res.data.message);
        }
        setLoading(false);
      } else {
        setLoading(false);
        setSubmitting(false);
        setStatus("The login detail is incorrect");
      }
      setTimeout(() => {
        setHasErrorsFailed(false);
        setHasErrorsSuccuss(false);
      }, 4000);
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Title */}
        <div className="text-start mb-6">
          <h1 className="text-dark font-17 font-bold mb-1">Forgot Password?</h1>
          {/* begin::Link */}
          <div className="text-muted fw-500 ">
            Enter your email to set your password.
          </div>
          {/* end::Link */}
        </div>
        {/* end::Title */}
        {/* begin::Title */}
        {hasErrorsFailed && (
          <div className="mb-lg-7 alert alert-danger">
            <div className="alert-text font-weight-bold"> {errorMessage}</div>
          </div>
        )}
        {hasErrorsSuccuss && (
          <div className="mb-7 bg-light-info p-4 rounded">
            <div className="text-info">{errorMessage}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-7">
          <label className="form-label text-dark">Email</label>
          <input
            type="email"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container text-danger">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-sm-sflex row g-0 justify-content-between pb-lg-0 flex-md-row-reverse">
          <div className="ps-md-2 col-sm-6 mb-3 mb-md-0">
            <button
              type="submit"
              id="kt_password_reset_submit"
              className="btn btn-primary fw-500 w-100"
              disabled={loading}
            >
              <span className="indicator-label">{isButton}</span>
              {loading && (
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          <Link to="/auth/login" className="pe-md-2 col-sm-6">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-secondary fw-500 w-100"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{" "}
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}
