import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { connect } from "react-redux";
import { HeaderUserMenu, QuickLinks, Search } from "../../../partials";
import { useLayout } from "../../core";
import ChangePassword from "./changePassword";
import { manageProfileAction } from "../../../redux/action/userProfile/action";
import { TEAM_MEMBER_PROFILE } from "../../../../app/pages/teamMembers/utils/TeamMemberAPIs";
import { getRequest } from "../../../helpers/APIHelper";
import { useNavigate } from "react-router-dom";
import { checkImage } from "../../../helpers/utils/CommonHelpers";

const toolbarButtonMarginClass = "ms-2 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
  toolbarButtonIconSizeClass = "ic";

const Topbar = (props: any) => {
  const { config } = useLayout();
  const [user, setUser] = useState<any>("");

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    setUser(props.profile);
  }, [props.profile]);

  const navigate = useNavigate();

  const getUserProfile = async () => {
    const url = TEAM_MEMBER_PROFILE;
    const userData = await getRequest(navigate, url, true);
    props.manageProfile(userData.data);
  };

  return (
    <>
      <div className="d-flex align-items-stretch flex-shrink-0">
        {/* begin::User */}
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
          id="kt_header_user_menu_toggle"
        >
          {/* begin::Toggle */}
          <div
            className={clsx(
              "cursor-pointer symbol btn btn-icon btn-active-light-primary btn-custom",
              toolbarUserAvatarHeightClass
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
          >
            <img
              height={24}
              width={24}
              src={checkImage(user.profile)}
              alt="Mis Moweb"
            />
          </div>
          <HeaderUserMenu user={user} />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
        {/* begin::Aside Toggler */}
        {config.header.left === "menu" && (
          <div
            className="d-flex align-items-center d-lg-none ms-1 me-n1"
            title="Show header menu"
          >
            <div
              className="btn btn-icon btn-active-light-primary w-40px h-40px"
              id="kt_header_menu_mobile_toggle"
            >
              <KTSVG
                path="/media/icons/duotune/text/txt001.svg"
                className="svg-icon-2"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.userProfile.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    manageProfile: (value: any) => {
      dispatch(manageProfileAction(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
